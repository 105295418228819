import React from 'react';
import { Container, Typography, Box, Card } from '@mui/material';
import { motion } from 'framer-motion';
import BackgroundAnimation from '../components/BackgroundAnimation';

const MotionContainer = motion(Container);
const MotionCard = motion(Card);
const MotionTypography = motion(Typography);
const MotionBox = motion(Box);

const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.6, staggerChildren: 0.1 }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const AboutUs = () => {
    return (
        <Box sx={{ minHeight: '100vh', position: 'relative', pt: 12, pb: 12 }}>
            <BackgroundAnimation />
            
            <MotionContainer 
                maxWidth="lg"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <MotionCard
                    variants={itemVariants}
                    sx={{
                        borderRadius: '24px',
                        background: 'linear-gradient(145deg, rgba(25,118,210,0.05) 0%, rgba(25,118,210,0.1) 100%)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(144, 202, 249, 0.2)',
                        p: { xs: 3, md: 6 },
                        mb: 6,
                    }}
                >
                    <MotionTypography
                        variant="h2"
                        sx={{
                            fontWeight: 700,
                            textAlign: 'center',
                            mb: 4,
                            background: 'linear-gradient(45deg, #fff, #90caf9)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        About Capitol Gains
                    </MotionTypography>

                    <MotionTypography 
                        variant="body1" 
                        sx={{ 
                            color: 'rgba(255,255,255,0.7)',
                            fontSize: '1.1rem',
                            lineHeight: 1.8,
                            mb: 3
                        }}
                    >
                        CapitolGains.io is your go-to platform for transparent, detailed insights into the stock trading activities of U.S. Congress members. We offer a comprehensive view of congressional trades, enriched with critical context such as stock values at the time of transactions, their performance over time, and the legislation Congress members are supporting while making specific trades.
                    </MotionTypography>

                    <MotionTypography 
                        variant="body1" 
                        sx={{ 
                            color: 'rgba(255,255,255,0.7)',
                            fontSize: '1.1rem',
                            lineHeight: 1.8,
                            mb: 3
                        }}
                    >
                        Our mission is to make this information accessible, understandable, and actionable for everyone. Whether you're tracking individual Congress members, uncovering patterns, or analyzing returns and transaction trends, CapitolGains.io provides the tools and analytics you need to turn raw data into actionable insights.
                    </MotionTypography>

                    <MotionTypography 
                        variant="body1" 
                        sx={{ 
                            color: 'rgba(255,255,255,0.7)',
                            fontSize: '1.1rem',
                            lineHeight: 1.8
                        }}
                    >
                        We simplify and enhance this data with clear visuals and straightforward analytics, empowering you to explore congressional trading with ease. CapitolGains.io makes it possible to monitor the financial decisions of elected officials and stay informed about their potential influences on the market.
                    </MotionTypography>
                </MotionCard>

                <MotionBox 
                    variants={itemVariants}
                    sx={{ mb: 6, textAlign: 'center' }}
                >
                    <MotionTypography
                        variant="h2"
                        sx={{
                            fontWeight: 700,
                            mb: 3,
                            background: 'linear-gradient(45deg, #fff, #90caf9)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        Who We Are
                    </MotionTypography>
                    <MotionTypography 
                        variant="h5" 
                        sx={{ 
                            color: 'rgba(255,255,255,0.7)',
                            mb: 6,
                            fontWeight: 500
                        }}
                    >
                        Capitol Gains is dedicated to providing transparent, non-partisan, and fact-based insights into the stock trading activities of U.S. Congress members. Our goal is to empower citizens to better understand how financial decisions influence politics while offering investors the opportunity to leverage this data to inform their own portfolios and potentially achieve returns comparable to Congress’s top performers.
                    </MotionTypography>
                </MotionBox>

                <MotionTypography
                    variant="h2"
                    sx={{
                        fontWeight: 700,
                        textAlign: 'center',
                        mb: 6,
                        background: 'linear-gradient(45deg, #fff, #90caf9)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                    }}
                >
                    Our Values
                </MotionTypography>

                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gap: 4 }}>
                    {[
                        {
                            "title": "Transparency",
                            "description": "We build trust through transparency. Our platform delivers clear, accessible, and verifiable data to help users make informed decisions with confidence."
                        },
                        {
                            "title": "Non-Partisanship",
                            "description": "Our commitment is to neutrality and objectivity. By presenting unbiased facts, we ensure that users can explore insights without political interference or bias."
                        },
                        {
                            "title": "Accuracy",
                            "description": "We are committed to providing precise and reliable data, guided by rigorous standards that ensure accuracy and uphold the integrity of insights we deliver."
                        },
                        {
                            "title": "Empowerment",
                            "description": "We equip users with knowledge and tools to unlock opportunities in the stock market and gain a deeper understanding of financial trends shaping politics."
                        },
                        {
                            "title": "Democratization",
                            "description": "We simplify access to congressional stock trades and enrich the data with essential context, empowering users to derive meaningful and actionable insights."
                        },
                        {
                            "title": "Innovation",
                            "description": "We embrace cutting-edge technology and data analysis to continuously enhance the user experience and deliver insights that matter most."
                        },
                        {
                            "title": "Integrity",
                            "description": "Our work is built on a foundation of ethical standards, ensuring honesty and transparency in every aspect of what we do."
                        }
                    ].map((value, index) => (
                        <MotionCard
                            key={index}
                            variants={itemVariants}
                            whileHover={{ scale: 1.02, boxShadow: '0px 8px 25px rgba(0,0,0,0.15)' }}
                            sx={{
                                borderRadius: '24px',
                                background: 'linear-gradient(145deg, rgba(25,118,210,0.05) 0%, rgba(25,118,210,0.1) 100%)',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(144, 202, 249, 0.2)',
                                p: 4,
                            }}
                        >
                            <MotionTypography
                                variant="h4"
                                sx={{
                                    fontWeight: 600,
                                    mb: 2,
                                    background: 'linear-gradient(45deg, #fff, #90caf9)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent'
                                }}
                            >
                                {value.title}
                            </MotionTypography>
                            <MotionTypography 
                                variant="body1" 
                                sx={{ 
                                    color: 'rgba(255,255,255,0.7)',
                                    fontSize: '1.1rem',
                                    lineHeight: 1.8
                                }}
                            >
                                {value.description}
                            </MotionTypography>
                        </MotionCard>
                    ))}
                </Box>
            </MotionContainer>
        </Box>
    );
};

export default AboutUs;