import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box, useTheme } from '@mui/material';

const StockPicksGraph = () => {
    const [data, setData] = useState([]);
    const [setActiveYear] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        fetch('/cg_track_record.json')
            .then(response => response.json())
            .then(jsonData => {
                const formattedData = jsonData.map(entry => ({
                    date: new Date(entry.date).toLocaleDateString('default', {
                        month: 'short',
                        year: 'numeric'
                    }),
                    "Capitol Gains ROI": entry.capitol_gains,
                    "S&P 500 ROI": entry.SP500,
                }));
                setData(formattedData);
            })
            .catch(error => console.error('Error loading data:', error));
    }, []);

    return (
        <Box sx={{
            height: '600px',
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
            p: 3,
        }}>
            <Box sx={{ height: '100%' }}>
                <ResponsiveBar
                    data={data}
                    keys={["Capitol Gains ROI", "S&P 500 ROI"]}
                    indexBy="date"
                    margin={{ top: 30, right: 140, bottom: 90, left: 70 }}
                    padding={0.2}
                    groupMode="grouped"
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={['rgba(33,150,243,1)', theme.palette.success.dark]}
                    borderRadius={2}
                    enableLabel={false}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 45,
                        legend: 'Date of Investment',
                        legendPosition: 'middle',
                        legendOffset: 70,
                        tickValues: data.filter((_, i) => i % 3 === 0).map(d => d.date)
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'ROI 1-Year Later (%)',
                        legendPosition: 'middle',
                        legendOffset: -50,
                        format: v => `${v}%`
                    }}
                    enableGridY={true}
                    gridYValues={[-40, -20, 0, 20, 40, 60, 80, 100, 120]}
                    animate={true}
                    motionConfig="gentle"
                    onClick={(node) => setActiveYear(node.indexValue)}
                    layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations']}
                    custom={{
                        barAriaLabel: e => `${e.id}: ${e.formattedValue}`,
                    }}
                    tooltip={({ id, value, indexValue }) => (
                        <Box sx={{
                            bgcolor: 'background.paper',
                            p: 1.5,
                            borderRadius: 1,
                            boxShadow: 2,
                            border: 1,
                            borderColor: 'divider',
                            minWidth: '200px'
                        }}>
                            <Box sx={{ fontWeight: 'medium', mb: 1 }}>{id}</Box>
                            <Box sx={{ fontSize: '0.875rem' }}>
                                Date: {indexValue}<br />
                                ROI: {value}%<br />
                                {data.find(d => d.date === indexValue) && 
                                    `Difference: ${(data.find(d => d.date === indexValue)["Capitol Gains ROI"] - 
                                    data.find(d => d.date === indexValue)["S&P 500 ROI"]).toFixed(2)}%`}
                            </Box>
                        </Box>
                    )}
                    legends={[{
                        dataFrom: 'keys',
                        anchor: 'top-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 6,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        symbolSize: 12,
                        symbolShape: 'square',
                        onClick: (data) => {
                            console.log('Legend clicked:', data);
                        }
                    }]}
                    theme={{
                        axis: {
                            domain: {
                                line: {
                                    stroke: theme.palette.divider
                                }
                            },
                            ticks: {
                                line: {
                                    stroke: theme.palette.divider
                                },
                                text: {
                                    fill: theme.palette.text.primary
                                }
                            },
                            legend: {
                                text: {
                                    fill: theme.palette.text.primary
                                }
                            }
                        },
                        grid: {
                            line: {
                                stroke: theme.palette.divider
                            }
                        },
                        legends: {
                            text: {
                                fill: theme.palette.text.primary
                            }
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default StockPicksGraph;