import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="refund-policy">
      <h1>Refund Policy</h1>

      <p><strong>Last Updated:</strong> 11/01/2024</p>

      <h2>1. Overview</h2>
      <p>
        At CapitolGains.io, we are committed to offering valuable insights and services to our users. This Refund Policy provides a comprehensive explanation of our refund procedures, eligibility requirements, and user responsibilities. By purchasing a subscription or service from CapitolGains.io, you agree to the terms of this Refund Policy. This policy applies solely to purchases made directly through CapitolGains.io and not through any third-party platforms, which may have their own refund policies.
      </p>

      <h2>2. Eligibility for Refunds</h2>
      <p>
        Refunds are granted under specific conditions and are at the sole discretion of CapitolGains.io. To be eligible for a refund, users must meet the following criteria:
      </p>
      <ul>
        <li>The refund request must be made within the first <strong>14 days</strong> from the date of the initial purchase of a subscription plan.</li>
        <li>The user must have experienced a substantial technical issue or service disruption that impacted the usability of our platform.</li>
        <li>The user must submit documented proof of the service issue, which may include screenshots, error messages, or descriptions of the problem encountered.</li>
      </ul>
      <p>
        CapitolGains.io reserves the right to investigate each refund request thoroughly to verify the issue's legitimacy. Refund requests that do not meet the above criteria or lack sufficient documentation may be denied.
      </p>

      <h2>3. Non-Refundable Items and Situations</h2>
      <p>
        Refunds are not available for certain items and situations, including but not limited to:
      </p>
      <ul>
        <li><strong>Partial months of service:</strong> Refunds will not be issued for unused days within a subscription period, whether the user cancels or downgrades the plan before the end of the billing cycle.</li>
        <li><strong>Subscription upgrades or downgrades:</strong> Changes in your subscription level during an active billing cycle are non-refundable. Fees for upgrades are charged immediately, while downgrades take effect at the next billing period.</li>
        <li><strong>One-time fees:</strong> Setup fees, additional service charges, and any optional add-ons are non-refundable under all circumstances.</li>
        <li><strong>Promotional offers and discounts:</strong> Any services obtained through a promotional discount or free trial are not eligible for refunds once the promotional or trial period has ended.</li>
      </ul>
      <p>
        CapitolGains.io retains the right to make exceptions on a case-by-case basis but is not obligated to issue refunds beyond the stated terms.
      </p>

      <h2>4. Requesting a Refund</h2>
      <p>
        To initiate a refund request, users must contact our customer support team at <strong>info@capitolgains.io</strong> within the eligible refund period. The request must include:
      </p>
      <ul>
        <li>Your full name and account email address registered with CapitolGains.io.</li>
        <li>The date of the transaction for which the refund is requested.</li>
        <li>A detailed explanation of the issue experienced, including supporting evidence as outlined in Section 2.</li>
      </ul>
      <p>
        Refund requests are typically reviewed within <strong>14 business days</strong>. Failure to provide complete information or required documentation may result in delays or denial of the refund.
      </p>

      <h2>5. Refund Processing</h2>
      <p>
        Upon approval of a refund request, CapitolGains.io will process the refund within <strong>14 business days</strong> to the original payment method used at the time of purchase. The timeframe for the refund to appear in your account may vary depending on your financial institution or credit card provider.
      </p>
      <p>
        Please be aware of the following conditions:
      </p>
      <ul>
        <li>CapitolGains.io is not responsible for any additional fees or delays imposed by your bank, credit card issuer, or payment service provider.</li>
        <li>Refunds will be issued in the original currency of the transaction. Any fluctuations in exchange rates or conversion fees from your financial institution are beyond the control of CapitolGains.io.</li>
        <li>Once a refund is processed, all access to the refunded subscription or service may be immediately terminated, including any associated features or data.</li>
      </ul>

      <h2>6. Chargebacks and Dispute Resolution</h2>
      <p>
        CapitolGains.io encourages users to reach out to our support team for assistance with refunds before initiating a chargeback or payment dispute. Unauthorized chargebacks are considered a violation of this policy and may result in account suspension, service cancellation, or legal action.
      </p>
      <p>
        If you are dissatisfied with our refund decision, you may request an appeal by contacting <strong>info@capitolgains.io</strong> and providing additional information or clarification. Appeals are reviewed on a case-by-case basis, but CapitolGains.io retains full discretion to deny further refunds or adjustments.
      </p>

      <h2>7. Modifications to the Refund Policy</h2>
      <p>
        CapitolGains.io reserves the right to modify, alter, or update this Refund Policy at any time. Significant changes to the policy will be communicated via our website, and the "Last Updated" date at the top of this page will reflect the latest modifications. Users are responsible for reviewing the Refund Policy periodically to remain informed about any updates. Your continued use of our services following changes constitutes your acceptance of the revised Refund Policy.
      </p>

      <h2>8. No Waiver of Terms</h2>
      <p>
        CapitolGains.io’s decision to grant or deny a refund in certain cases does not waive our right to enforce these policies in future transactions. Any waiver of this Refund Policy will be effective only in writing and signed by an authorized representative of CapitolGains.io.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have questions or need further clarification regarding this Refund Policy, please contact our support team at <strong>info@capitolgains.io</strong> or through our contact form on the website. Our support team will be happy to assist you in understanding this policy and addressing any concerns.
      </p>
    </div>
  );
};

export default RefundPolicy;