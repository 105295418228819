import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    Alert,
    Tooltip,
    IconButton,
    Box,
    Stack,
    Link,
    Avatar,
    Card,
    CardContent,
    Button
} from '@mui/material';
import {
    InfoOutlined,
    TrendingUp,
    Warning,
    TrendingDown,
} from '@mui/icons-material';


let lastScrollTop = 0;
let lastScrollTime = Date.now();

const getScrollSpeed = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const currentTime = Date.now();
    const timeDifference = currentTime - lastScrollTime;
    const scrollDistance = Math.abs(currentScrollTop - lastScrollTop);
    const speed = scrollDistance / timeDifference;
    lastScrollTop = currentScrollTop;
    lastScrollTime = currentTime;
    return speed;
};

const MotionCard = motion(Card);

const StockCard = ({ stock, index, dynamicThreshold }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: dynamicThreshold,
    });

    const scrollSpeed = getScrollSpeed();
    const animationDuration = scrollSpeed > 0.5 ? 0.2 : 0.6;

    const fadeInVariant = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: animationDuration, ease: 'easeInOut' },
        },
    };

    // Function to get score label
    const getScoreLabel = (score) => {
        if (score < -5) return "Very Bearish";
        if (score < -3) return "Bearish";
        if (score < -1) return "Moderately Bearish";
        if (score < 1) return "Neutral / Hold";
        if (score < 3) return "Moderately Bullish";
        if (score < 5) return "Bullish";
        return "Very Bullish";
    };

    // Function to get score color
    const getScoreColor = (score) => {
        if (score < -3) return '#ef5350';        // Strong red
        if (score < -1) return '#ff7043';        // Light red
        if (score < 1) return '#ffd54f';         // Yellow
        if (score < 3) return '#81c784';         // Light green
        return '#4caf50';                        // Strong green
    };

    // Calculate normalized position for the marker (between 0 and 100)
    const getMarkerPosition = (score) => {
        // Clamp score between -5 and 5
        const clampedScore = Math.max(-5, Math.min(5, score));
        // Convert from -5:5 range to 0:100 range
        return ((clampedScore + 5) / 10) * 100;
    };

    const logoUrl = `https://img.logo.dev/ticker/${stock.ticker}?token=pk_Fy4ZV5RMQEKzNJfSy9h6BA`;
    const scoreLabel = getScoreLabel(stock.score - 1);
    const markerPosition = getMarkerPosition(stock.score - 1);
    const scoreColor = getScoreColor(stock.score - 1);

    return (
        <Grid item xs={12} md={6} key={index}>
            <motion.div
                ref={ref}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={fadeInVariant}
            >
                <MotionCard
                    elevation={3}
                    whileHover={{ scale: 1.02, boxShadow: '0px 8px 25px rgba(0,0,0,0.15)' }}
                    sx={{
                        borderRadius: '24px',
                        background: 'linear-gradient(145deg, rgba(25,118,210,0.05) 0%, rgba(25,118,210,0.1) 100%)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(144, 202, 249, 0.2)',
                    }}
                >
                    <Box
                        sx={{
                            background: 'linear-gradient(135deg, rgba(25,118,210,0.2) 0%, rgba(25,118,210,0.4) 100%)',
                            padding: 3,
                            borderTopLeftRadius: '24px',
                            borderTopRightRadius: '24px',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Avatar
                                src={logoUrl}
                                alt={`${stock.ticker} logo`}
                                sx={{ 
                                    width: 60, 
                                    height: 60, 
                                    marginRight: 2,
                                    border: '2px solid rgba(144, 202, 249, 0.3)',
                                    background: '#1a1a1a'
                                }}
                            />
                            <Typography 
                                variant="h5" 
                                sx={{
                                    color: 'white',
                                    fontWeight: 600,
                                    textShadow: '0px 2px 4px rgba(0,0,0,0.2)'
                                }}
                            >
                                <Link
                                    href={`https://finance.yahoo.com/quote/${stock.ticker}`}
                                    target="_blank"
                                    rel="noopener"
                                    sx={{
                                        color: 'white',
                                        textDecoration: 'none',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        }
                                    }}
                                >
                                    {`${stock.rank}. ${stock.ticker}`}
                                </Link>
                            </Typography>
                        </Box>

                        {/* Score Spectrum */}
                        <Box sx={{ mt: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                                    SELL
                                </Typography>
                                <Typography 
                                    variant="subtitle1" 
                                    sx={{ 
                                        color: scoreColor,
                                        fontWeight: 'bold',
                                        textShadow: '0px 2px 4px rgba(0,0,0,0.2)'
                                    }}
                                >
                                    {scoreLabel}
                                </Typography>
                                <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                                    BUY
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    height: '8px',
                                    background: 'linear-gradient(to right, #ef5350, #ffd54f, #4caf50)',
                                    borderRadius: '4px',
                                    position: 'relative',
                                }}
                            >
                                {/* Score Marker */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: `${markerPosition}%`,
                                        transform: 'translateX(-50%)',
                                        width: '16px',
                                        height: '16px',
                                        backgroundColor: 'white',
                                        border: `3px solid ${scoreColor}`,
                                        borderRadius: '50%',
                                        top: '-5px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <CardContent sx={{ p: 4 }}>
                        <Stack spacing={2}>
                            <Box>
                                <Typography variant="subtitle2" color="primary" gutterBottom>
                                    Congress Members Purchased
                                </Typography>
                                <Typography variant="body1" color="text.primary">
                                    {stock.congress_members_purchased.length > 0 ? 
                                        stock.congress_members_purchased.join(', ') : 
                                        'None'}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="primary" gutterBottom>
                                    Congress Members Sold
                                </Typography>
                                <Typography variant="body1" color="text.primary">
                                    {stock.congress_members_sold.length > 0 ? 
                                        stock.congress_members_sold.join(', ') : 
                                        'None'}
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </MotionCard>
            </motion.div>
        </Grid>
    );
};

function StockRankings() {
    const [rankings, setRankings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [visibleItems, setVisibleItems] = useState(10);
    const [sortOrder, setSortOrder] = useState('bullish');

    const scrollSpeed = getScrollSpeed();
    const dynamicThreshold = scrollSpeed > 0.5 ? 0.5 : 0.2;
    const { ref, inView } = useInView({ threshold: 0.3 });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.6,
                staggerChildren: 0.1
            }
        }
    };

    // Handle sort change
    const handleSortChange = (event, newOrder) => {
        if (newOrder !== null) {
            setSortOrder(newOrder);
        }
    };

    // Sort rankings based on sort order
    const getSortedRankings = () => {
        if (!rankings) return [];
        
        return [...rankings].sort((a, b) => {
            if (sortOrder === 'bullish') {
                return (b.score - 1) - (a.score - 1); // Highest to lowest
            } else {
                return (a.score - 1) - (b.score - 1); // Lowest to highest
            }
        });
    };

    useEffect(() => {
        const fetchStockRankings = async () => {
            try {
                const session = await fetchAuthSession();
                if (session && session.tokens && session.tokens.idToken) {
                    const idToken = session.tokens.idToken.toString();
                    const response = await fetch('https://api.capitolgains.io/user/rankings', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${idToken}`
                        }
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    if (data.rankings && Array.isArray(data.rankings)) {
                        setRankings(data.rankings);
                    } else {
                        throw new Error('Unexpected API response format');
                    }
                } else {
                    throw new Error('Session is invalid or missing ID token');
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchStockRankings();
    }, []);

    useEffect(() => {
        if (inView && visibleItems < rankings.length) {
            setVisibleItems((prevVisibleItems) => prevVisibleItems + 20);
        }
    }, [inView, visibleItems, rankings.length]);

    if (loading) {
        return (
            <Container sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={60} thickness={4} />
            </Container>
        );
    }

    if (error) {
        return (
            <Container sx={{ pt: 8 }}>
                <Alert 
                    severity="error"
                    sx={{
                        borderRadius: '12px',
                        background: 'rgba(244,67,54,0.1)',
                        border: '1px solid rgba(244,67,54,0.3)'
                    }}
                >
                    Error: {error.message}
                </Alert>
            </Container>
        );
    }

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <Container sx={{ pt: 8, pb: 12 }}>




                <Box 
                    sx={{ 
                        mb: 6,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: 2
                    }}
                >
                    <Stack 
                        direction="row" 
                        alignItems="center" 
                        spacing={2}
                    >
                        <Typography 
                            variant="h3" 
                            sx={{
                                background: 'linear-gradient(45deg, #fff, #90caf9)',
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                fontWeight: 700
                            }}
                        >
                            Stock Rankings
                        </Typography>
                        <Tooltip
                            title={
                                <>
                                    <Typography variant="body2" color="inherit">
                                        Learn more about how stock rankings are determined, including the factors that influence them.
                                    </Typography>
                                    <Link 
                                        component={RouterLink} 
                                        to="/stock-ranking-info" 
                                        sx={{ 
                                            color: 'inherit',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                            display: 'block',
                                            mt: 1,
                                            textDecoration: 'underline',
                                            '&:hover': {
                                                color: '#90caf9'
                                            }
                                        }}
                                    >
                                        Click here for more details →
                                    </Link>
                                </>
                            }
                        >
                            <IconButton sx={{ color: 'primary.main' }}>
                                <InfoOutlined />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Stack 
                        direction="row" 
                        sx={{ 
                            background: 'rgba(30, 41, 59, 0.8)',
                            borderRadius: '12px',
                            p: '4px',
                            border: '1px solid rgba(148, 163, 184, 0.1)',
                            backdropFilter: 'blur(8px)',
                        }}
                    >
                        <Button
                            onClick={() => handleSortChange(null, 'bullish')}
                            startIcon={<TrendingUp />}
                            sx={{
                                px: 3,
                                py: 1,
                                color: sortOrder === 'bullish' ? '#fff' : 'rgb(148, 163, 184)',
                                bgcolor: sortOrder === 'bullish' ? 'rgba(59, 130, 246, 0.2)' : 'transparent',
                                borderRadius: '8px',
                                '&:hover': {
                                    bgcolor: sortOrder === 'bullish' 
                                        ? 'rgba(59, 130, 246, 0.3)' 
                                        : 'rgba(148, 163, 184, 0.1)',
                                },
                                transition: 'all 0.2s ease',
                                textTransform: 'uppercase',
                                fontWeight: 500,
                                fontSize: '0.875rem',
                                letterSpacing: '0.025em',
                            }}
                        >
                            Most Bullish
                        </Button>
                        <Button
                            onClick={() => handleSortChange(null, 'bearish')}
                            startIcon={<TrendingDown />}
                            sx={{
                                px: 3,
                                py: 1,
                                color: sortOrder === 'bearish' ? '#fff' : 'rgb(148, 163, 184)',
                                bgcolor: sortOrder === 'bearish' ? 'rgba(59, 130, 246, 0.2)' : 'transparent',
                                borderRadius: '8px',
                                '&:hover': {
                                    bgcolor: sortOrder === 'bearish' 
                                        ? 'rgba(59, 130, 246, 0.3)' 
                                        : 'rgba(148, 163, 184, 0.1)',
                                },
                                transition: 'all 0.2s ease',
                                textTransform: 'uppercase',
                                fontWeight: 500,
                                fontSize: '0.875rem',
                                letterSpacing: '0.025em',
                            }}
                        >
                            Most Bearish
                        </Button>
                    </Stack>
                </Box>

                <Card
                    sx={{
                        p: 3,
                        mb: 6,
                        borderRadius: '16px',
                        background: 'linear-gradient(145deg, rgba(25,118,210,0.05) 0%, rgba(25,118,210,0.1) 100%)',
                        border: '1px solid rgba(144, 202, 249, 0.2)',
                        backdropFilter: 'blur(10px)',
                    }}
                >
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Warning 
                            sx={{ 
                                color: 'primary.main',
                                opacity: 0.8 
                            }} 
                        />
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                color: 'rgba(255,255,255,0.7)',
                                lineHeight: 1.6
                            }}
                        >
                            Users are responsible for their own trading decisions, and Capitol Gains is not liable for any financial outcomes resulting from the use of this information.<br/>
                            These rankings are for informational purposes only and do not constitute financial advice.
                        </Typography>
                    </Stack>
                </Card>

                <Grid container spacing={4}>
                    {getSortedRankings().slice(0, visibleItems).map((stock, index) => (
                        <StockCard 
                            key={stock.ticker} 
                            stock={{...stock, rank: index + 1}} 
                            index={index} 
                            dynamicThreshold={dynamicThreshold} 
                        />
                    ))}
                </Grid>

                {visibleItems < rankings.length && (
                    <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }} />
                )}
            </Container>
        </motion.div>
    );
}

export default StockRankings;