import React, { useState, useEffect, useMemo } from 'react';
import {
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Box,
    Chip,
    Alert,
    Avatar,
} from '@mui/material';
import { fetchAuthSession } from 'aws-amplify/auth';
import { debounce } from 'lodash'; 

function RecentTrades() {
    const currentYear = new Date().getFullYear();
    const [trades, setTrades] = useState([]);
    const [numTradesFiltered, setNumTradesFiltered] = useState(0);
    const [filters, setFilters] = useState({
        year: currentYear,
        first_name: '',
        last_name: '',
        chamber: '',
        asset_code: '',
        transaction: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchTrades = async (searchFilters) => {
        setLoading(true);
        setError(null);

        try {
            const session = await fetchAuthSession();
            if (session?.tokens?.idToken) {
                const idToken = session.tokens.idToken.toString();
                
                const payload = Object.fromEntries(
                    Object.entries(searchFilters).filter(([_, value]) => value)
                );

                const response = await fetch('https://api.capitolgains.io/user/trades', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setTrades(data.disclosures);
                setNumTradesFiltered(data.num_trades_filtered);
            }
        } catch (error) {
            console.error('Error fetching recent trades:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Create memoized debounced fetch function
    const debouncedFetch = useMemo(
        () => debounce((searchFilters) => {
            fetchTrades(searchFilters);
        }, 2000),
        [] // Empty dependency array since fetchTrades is stable
    );

    // Handle filter changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => {
            const newFilters = {
                ...prev,
                [name]: value
            };
            // Call debounced fetch with new filters
            debouncedFetch(newFilters);
            return newFilters;
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        debouncedFetch.cancel();
        fetchTrades(filters);
    };

    // Cleanup
    useEffect(() => {
        return () => {
            debouncedFetch.cancel();
        };
    }, [debouncedFetch]);

    // Initial fetch
    useEffect(() => {
        fetchTrades(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const capitalizeWords = (str) => {
        if (!str) return '';
        const capitalized = str.replace(/\b\w/g, char => char.toUpperCase());
        if (capitalized.length > 60) {
            return capitalized.slice(0, 60) + '...';
        }
        return capitalized;
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Recent Congressional Trades
            </Typography>

            {numTradesFiltered > 0 && (
                <Alert severity="info" sx={{ mb: 3 }}>
                    Congress disclosed {numTradesFiltered} trades this month! 
                    Sign up for premium to get full access to the most recent trades.
                    Free-tier users only have access to trades from one month ago or older.
                </Alert>
            )}

            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            name="year"
                            label="Year"
                            type="number"
                            value={filters.year}
                            onChange={handleFilterChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            name="first_name"
                            label="First Name"
                            value={filters.first_name}
                            onChange={handleFilterChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            name="last_name"
                            label="Last Name"
                            value={filters.last_name}
                            onChange={handleFilterChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Chamber</InputLabel>
                            <Select
                                name="chamber"
                                value={filters.chamber}
                                onChange={handleFilterChange}
                                label="Chamber"
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="HOUSE">House</MenuItem>
                                <MenuItem value="SENATE">Senate</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Asset Code</InputLabel>
                            <Select
                                name="asset_code"
                                value={filters.asset_code}
                                onChange={handleFilterChange}
                                label="Asset Code"
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="ST">Stocks</MenuItem>
                                <MenuItem value="OP">Options</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Transaction</InputLabel>
                            <Select
                                name="transaction"
                                value={filters.transaction}
                                onChange={handleFilterChange}
                                label="Transaction"
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="purchase">Purchase</MenuItem>
                                <MenuItem value="sale">Sale</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>

            {loading ? (
                <Grid container justifyContent="center" sx={{ mt: 4 }}>
                    <CircularProgress />
                </Grid>
            ) : error ? (
                <Alert severity="error">Error: {error.message}</Alert>
            ) : (
                <Grid container spacing={3} sx={{ mt: 3 }}>
                    {trades.map((trade, index) => (
                        <Grid item xs={12} key={index}>
                            <Card 
                                sx={{ 
                                    borderRadius: 2,
                                    background: 'linear-gradient(to right bottom, #1a1a1a, #2d2d2d)',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    overflow: 'hidden'
                                }}
                            >
                                <CardHeader
                                    sx={{
                                        background: 'linear-gradient(to right, rgba(0,0,0,0.4), rgba(0,0,0,0.2))',
                                        borderBottom: '1px solid rgba(255,255,255,0.1)',
                                        p: 2.5
                                    }}
                                    avatar={
                                        <Avatar
                                            src={`https://img.logo.dev/ticker/${trade.ticker}?token=pk_Fy4ZV5RMQEKzNJfSy9h6BA`}
                                            alt={trade.ticker}
                                            sx={{ 
                                                width: 48, 
                                                height: 48,
                                                border: '2px solid rgba(255,255,255,0.1)',
                                                background: '#fff'
                                            }}
                                        />
                                    }
                                    title={
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box>
                                                <Typography variant="h6" sx={{ 
                                                    color: '#fff',
                                                    fontWeight: 600,
                                                    mb: 0.5 
                                                }}>
                                                    {trade.ticker}
                                                </Typography>
                                                <Typography variant="body2" sx={{ 
                                                    color: 'rgba(255,255,255,0.7)',
                                                    fontWeight: 500
                                                }}>
                                                    {capitalizeWords(trade.asset_name)}
                                                </Typography>
                                            </Box>
                                            <Chip 
                                                label={trade.transaction.toUpperCase()}
                                                color={trade.transaction === 'purchase' ? 'success' : 'error'}
                                                size="small"
                                                sx={{ 
                                                    borderRadius: '16px',
                                                    fontWeight: 600,
                                                    px: 1
                                                }}
                                            />
                                        </Box>
                                    }
                                />
                                <CardContent sx={{ 
                                    background: 'rgba(255,255,255,0.05)',
                                    p: 3
                                }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Box sx={{ mb: 2 }}>
                                                {trade.asset_code && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Asset Type:
                                                        </span>
                                                        {trade.asset_code === 'OP' ? 'Option' : 'Stock'}
                                                    </Typography>
                                                )}
                                                {trade.asset_code === 'OP' && trade.option_type && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Option Type:
                                                        </span>
                                                        {trade.option_type === 'call' ? 'Call' : 'Put'}
                                                    </Typography>
                                                )}
                                                {trade.transaction_date && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Transaction Date:
                                                        </span>
                                                        {trade.transaction_date}
                                                    </Typography>
                                                )}
                                                {trade.strike_price != null && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Strike Price:
                                                        </span>
                                                        ${trade.strike_price.toFixed(2)}
                                                    </Typography>
                                                )}
                                                {trade.option_exp_date != null && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Expiration Date:
                                                        </span>
                                                        {trade.option_exp_date}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box sx={{ mb: 2 }}>
                                                {trade.first_name && trade.last_name && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Member:
                                                        </span>
                                                        {trade.first_name} {trade.last_name} ({trade.governing_body})
                                                    </Typography>
                                                )}
                                                {trade.asset_value_low != null && trade.asset_value_high != null && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Value Range:
                                                        </span>
                                                        ${trade.asset_value_low.toLocaleString()} - ${trade.asset_value_high.toLocaleString()}
                                                    </Typography>
                                                )}
                                                {trade.stock_price != null && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Stock Price:
                                                        </span>
                                                        ${trade.stock_price}
                                                    </Typography>
                                                )}
                                                {trade.stock_price_1yr != null && (
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            color: 'rgba(255,255,255,0.9)',
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <span style={{ 
                                                            color: 'rgba(255,255,255,0.6)',
                                                            fontWeight: 500 
                                                        }}>
                                                            Price After 1 Year:
                                                        </span>
                                                        ${trade.stock_price_1yr}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ 
                                        mt: 2,
                                        pt: 2,
                                        borderTop: '1px solid rgba(255,255,255,0.1)'
                                    }}>
                                        {trade.stock_roi_1yr != null && (
                                            <Typography 
                                                variant="body2" 
                                                sx={{ 
                                                    color: ((trade.stock_roi_1yr - 1) * 100) > 0 ? '#4caf50' : '#f44336',
                                                    mb: 1,
                                                    fontWeight: 600,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1
                                                }}
                                            >
                                                <span style={{ 
                                                    color: 'rgba(255,255,255,0.6)',
                                                    fontWeight: 500 
                                                }}>
                                                    1 Year ROI:
                                                </span>
                                                {((trade.stock_roi_1yr - 1) * 100).toFixed(2)}%
                                            </Typography>
                                        )}
                                        {trade.asset_code === 'OP' && trade.option_roi != null && (
                                            <Typography 
                                                variant="body2" 
                                                sx={{ 
                                                    color: trade.option_roi > 0 ? '#4caf50' : '#f44336',
                                                    fontWeight: 600,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1
                                                }}
                                            >
                                                <span style={{ 
                                                    color: 'rgba(255,255,255,0.6)',
                                                    fontWeight: 500 
                                                }}>
                                                    Option ROI:
                                                </span>
                                                {(trade.option_roi * 100).toFixed(2)}%
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    );
}

export default RecentTrades;