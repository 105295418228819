import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import StockRankings from './pages/StockRankings';
import TradingLeaderboard from './pages/TradingLeaderBoard';
import Home from './pages/Home';
import RecentTrades from './pages/RecentTrades';
import AboutUs from './pages/AboutUs';
import Pricing from './pages/Pricing';
import ManageSubscription from './pages/ManageSubscription';
import AccountWrapper from './pages/Account';
import CongressMemberProfile from './pages/CongressMemberProfile';
import StockRankingsInfo from './pages/StockRankingsInfo';
import TermsOfService from './pages/TermsOfService';
import RefundPolicy from './pages/RefundPolicy';
import CookiePolicy from './pages/CookiePolicy';
import FAQ from './pages/FAQ';
import Disclaimer from './pages/Disclaimer';
import SubscriptionAgreement from './pages/SubscriptionAgreement';
import AcceptableUsePolicy from './pages/AcceptableUsePolicy';
import ContactInformation from './pages/ContactInformation';
import ComplianceDisclosures from './pages/ComplianceDisclosure';
import AccessibilityStatement from './pages/AccessibilityStatement';
import DEIPage from './pages/DEI';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactPage from './pages/ContactPage'; 
import { createTheme, ThemeProvider, Container, CssBaseline } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import ScrollToTopButton from './components/ScrollToTopButton';
import StripeContext from './context/StripeContext';
import ScrollToTop from './components/ScrollToTop'; 
import './App.css';

// Initialize Google Analytics
ReactGA.initialize("G-8ZVH7W0TJM"); // Replace with your Measurement ID

// Create a dark theme using Material-UI
const customTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#f48fb1',
        },
        background: {
            default: '#1b1f21',
            paper: '#1d1d1d',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0bec5',
        },
    },
});

function App() {
    const [dollarsEnabled, setDollarsEnabled] = useState(false);

    useEffect(() => {
        document.title = "CapitolGains.io";
    }, []);

    const toggleDollars = () => {
        setDollarsEnabled((prev) => !prev);
    };

    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <StripeContext>
                <Router>
                    <ScrollToTop />
                    <Authenticator.Provider>
                        <AppContent dollarsEnabled={dollarsEnabled} toggleDollars={toggleDollars} />
                    </Authenticator.Provider>
                </Router>
            </StripeContext>
        </ThemeProvider>
    );
}

function AppContent({ dollarsEnabled, toggleDollars }) {
    const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
    const location = useLocation();

    useEffect(() => {
        // Track page views on location (route) change
        ReactGA.send("pageview", location.pathname + location.search);
    }, [location]);

    return (
        <div className="App">
            <Navbar user={user} signOut={signOut} toggleDollars={toggleDollars} dollarsEnabled={dollarsEnabled} />
            <Container className="ContentContainer">
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<Home dollarsEnabled={dollarsEnabled} />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/stock-ranking-info" element={<StockRankingsInfo />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/refund-policy" element={<RefundPolicy />} />
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    <Route path="/subscription-agreement" element={<SubscriptionAgreement />} />
                    <Route path="/acceptable-use-policy" element={<AcceptableUsePolicy />} />
                    <Route path="/contact-information" element={<ContactInformation />} />
                    <Route path="/compliance-disclosures" element={<ComplianceDisclosures />} />
                    <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/anti-DEI-policy" element={<DEIPage />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/congressmember/:memberId" element={<CongressMemberProfile />} />

                    {/* Sign In Route */}
                    <Route path="/sign-in" element={<SignInRoute />} />

                    {/* Sign Up Route */}
                    <Route path="/sign-up" element={<SignUpRoute />} />

                    {/* Protected Routes */}
                    <Route path="/stocks" element={<ProtectedRoute user={user}><StockRankings user={user} /></ProtectedRoute>} />
                    <Route path="/leaderboard" element={<ProtectedRoute user={user}><TradingLeaderboard user={user} /></ProtectedRoute>} />
                    <Route path="/recent-trades" element={<ProtectedRoute user={user}><RecentTrades user={user} /></ProtectedRoute>} />
                    <Route path="/manage-subscription" element={<ProtectedRoute user={user}><ManageSubscription user={user} /></ProtectedRoute>} />
                    <Route path="/account" element={<ProtectedRoute user={user}><AccountWrapper user={user} /></ProtectedRoute>} />
                </Routes>
            </Container>
            <ScrollToTopButton />
            <Footer />
        </div>
    );
}

function ProtectedRoute({ user, children }) {
    if (!user) {
        return <Navigate to="/sign-in" replace />;
    }
    return children;
}

function SignInRoute() {
    const { user } = useAuthenticator((context) => [context.user]);

    if (user) {
        return <Navigate to="/" replace />;
    }

    return (
        <Authenticator
            hideSignUp={false}
            socialProviders={['google']}  // Add Google as a social provider
        />
    );
}

function SignUpRoute() {
    const { user } = useAuthenticator((context) => [context.user]);

    if (user) {
        return <Navigate to="/" replace />;
    }

    return (
        <Authenticator
            initialState="signUp"
            hideSignUp={false}
        />
    );
}

export default App;