import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import XIcon from '@mui/icons-material/X';

const Footer = () => {
  return (
    <Box component="footer" sx={{ 
      bgcolor: '#1A1A1A',
      color: 'grey.200',
      py: 12,
      mt: 8
    }}>
      <Container maxWidth="lg">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h5" 
              sx={{ 
                color: '#60A5FA',
                fontWeight: 'bold',
                mb: 3
              }}
            >
              CapitolGains.io
            </Typography>
            <Typography variant="body2" sx={{ color: 'grey.400', maxWidth: 300, lineHeight: 1.7 }}>
              Providing transparency on stock trading activities by U.S. Congress members.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', color: 'white' }}>
              Quick Links
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {['Home', 'About Us', 'Pricing', 'Contact', 'FAQ'].map((item) => (
                <Link 
                  key={item}
                  href={`/${item.toLowerCase().replace(' ', '-')}`}
                  sx={{
                    color: 'grey.400',
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    '&:hover': {
                      color: 'common.white',
                      transform: 'translateX(4px)',
                      display: 'inline-block'
                    }
                  }}
                >
                  {item}
                </Link>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', color: 'white' }}>
              Connect With Us
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <IconButton 
                href="https://x.com/capitolgains_io"
                target="_blank"
                sx={{
                  bgcolor: 'rgba(255,255,255,0.1)',
                  color: 'grey.200',
                  '&:hover': {
                    bgcolor: 'rgba(255,255,255,0.2)'
                  }
                }}
              >
                <XIcon />
              </IconButton>

              <Box component="a"
                href="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  px: 3,
                  py: 1,
                  bgcolor: 'rgba(255,255,255,0.1)',
                  borderRadius: 1,
                  color: 'grey.200',
                  textDecoration: 'none',
                  transition: 'all 0.2s',
                  '&:hover': {
                    bgcolor: 'rgba(255,255,255,0.2)',
                    transform: 'translateX(4px)'
                  }
                }}
              >
                Contact Us
                <Box component="svg" 
                  sx={{ ml: 1, width: 16, height: 16 }}
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ 
          mt: 12, 
          pt: 6, 
          borderTop: 1, 
          borderColor: 'rgba(255,255,255,0.1)'
        }}>
          <Grid container spacing={3} justifyContent="center">
            {[
              'Terms of Service',
              'Privacy Policy',
              'Refund Policy',
              'Cookie Policy',
              'Disclaimer',
              'Subscription Agreement',
              'Acceptable Use Policy',
              'Accessibility Statement'
            ].map((policy) => (
              <Grid item key={policy}>
                <Link
                  href={`/${policy.toLowerCase().replace(/\s+/g, '-')}`}
                  sx={{
                    color: 'grey.500',
                    textDecoration: 'none',
                    fontSize: '0.875rem',
                    '&:hover': { color: 'grey.300' }
                  }}
                >
                  {policy}
                </Link>
              </Grid>
            ))}
          </Grid>
          
          <Typography variant="body2" align="center" sx={{ color: 'grey.500', mt: 6 }}>
            © {new Date().getFullYear()} CapitolGains.io. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;