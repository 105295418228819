// capitol-gains-ui/src/pages/CongressMemberProfile.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, Avatar, Paper, Grid, Divider, Link } from '@mui/material';
import { fetchAuthSession } from 'aws-amplify/auth';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GavelIcon from '@mui/icons-material/Gavel';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

function CongressMemberProfile() {
  const { memberId } = useParams();
  const [memberData, setMemberData] = useState(null);
  const [legislationData, setLegislationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken.toString();

        const response = await fetch(`https://api.capitolgains.io/member`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ bioguideId: memberId }),
        });

        if (!response.ok) throw new Error('Failed to fetch member data');
        const data = await response.json();
        setMemberData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchLegislationData = async () => {
      try {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken.toString();

        const response = await fetch(`https://api.capitolgains.io/member/legislation`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ bioguideId: memberId }),
        });

        if (!response.ok) throw new Error('Failed to fetch legislation data');
        const legislation = await response.json();
        setLegislationData(legislation);
      } catch (err) {
        console.error('Error fetching legislation:', err);
      }
    };

    fetchMemberData();
    fetchLegislationData();
  }, [memberId]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>{error.message}</Typography>;

  return (
    <Container>
      {memberData && (
        <>
          {/* Profile Header with Member Details */}
          <Box display="flex" alignItems="center" mb={4}>
            <Avatar src={memberData.member_details.image} alt={memberData.name} sx={{ width: 120, height: 120, mr: 3 }} />
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#ffffff' }}>{memberData.name}</Typography>
              <Typography variant="body1" color="textSecondary" sx={{ color: '#B0BEC5' }}>
                {memberData.member_details.party} - {memberData.member_details.state} - Born: {memberData.member_details.additional_details.birthYear}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ color: '#B0BEC5' }}>
                Office: {memberData.member_details.additional_details.addressInformation.officeAddress} | Phone: {memberData.member_details.additional_details.addressInformation.phoneNumber}
              </Typography>
              <Typography variant="body2">
                <a href={memberData.member_details.website} target="_blank" rel="noreferrer" style={{ color: '#82B1FF' }}>
                  Official Website
                </a>
              </Typography>
            </Box>
          </Box>

          {/* Trading Stats */}
          <Paper sx={{ p: 3, mb: 4, background: 'linear-gradient(to right, #1c1c1c, #2c2c2c)', color: '#ffffff', borderRadius: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', color: '#76ff03' }}>
              <TrendingUpIcon fontSize="medium" sx={{ mr: 1 }} /> Trading Stats
            </Typography>
            <Divider sx={{ mb: 2, backgroundColor: '#616161' }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="subtitle1" sx={{ color: '#82B1FF' }}>Bullish Transactions</Typography>
                  <Typography>Count: {memberData.bullish_transactions.count}</Typography>
                  <Typography>ROI: {memberData.bullish_transactions.roi}</Typography>
                  <Typography>Accuracy: {memberData.bullish_transactions.accuracy * 100}%</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="subtitle1" sx={{ color: '#FFAB91' }}>Bearish Transactions</Typography>
                  <Typography>Count: {memberData.bearish_transactions.count}</Typography>
                  <Typography>ROI: {memberData.bearish_transactions.roi}</Typography>
                  <Typography>Accuracy: {memberData.bearish_transactions.accuracy * 100}%</Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          {/* Terms in Office */}
          <Paper sx={{ p: 3, mb: 4, background: 'linear-gradient(to right, #1c1c1c, #2c2c2c)', color: '#ffffff', borderRadius: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', color: '#76ff03' }}>
              <WorkHistoryIcon fontSize="medium" sx={{ mr: 1 }} /> Terms in Office
            </Typography>
            <Divider sx={{ mb: 2, backgroundColor: '#616161' }} />
            {memberData.member_details.additional_details.terms.map((term, index) => (
              <Typography key={index} variant="body1">
                {term.memberType} in {term.chamber}, {term.startYear} - {term.endYear || "Present"}
              </Typography>
            ))}
          </Paper>

          {/* Legislation */}
          <Paper sx={{ p: 3, mb: 4, background: 'linear-gradient(to right, #1c1c1c, #2c2c2c)', color: '#ffffff', borderRadius: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', color: '#76ff03' }}>
              <GavelIcon fontSize="medium" sx={{ mr: 1 }} /> Supported Legislation
            </Typography>
            <Divider sx={{ mb: 2, backgroundColor: '#616161' }} />
            <Typography>Cosponsored Legislation: {memberData.member_details.additional_details.cosponsoredLegislation.count}</Typography>
            <Typography>Sponsored Legislation: {memberData.member_details.additional_details.sponsoredLegislation.count}</Typography>

            {legislationData.length > 0 ? (
              <Box mt={2}>
                {legislationData.map((bill, index) => (
                  <Box key={index} mb={2}>
                    <Typography variant="subtitle1" sx={{ color: '#82B1FF', fontWeight: 'bold' }}>{bill.title}</Typography>
                    <Typography>Type: {bill.type} | Congress: {bill.congress}</Typography>
                    <Typography>Introduced: {new Date(bill.introducedDate).toLocaleDateString()}</Typography>
                    <Typography>Policy Area: {bill.policyArea ? bill.policyArea.name : 'N/A'}</Typography>
                    <Typography>
                      Latest Action: {bill.latestAction ? bill.latestAction.text : 'N/A'} on{' '}
                      {bill.latestAction ? new Date(bill.latestAction.actionDate).toLocaleDateString() : 'N/A'}
                    </Typography>
                    <Link href={bill.url} target="_blank" rel="noopener" sx={{ color: '#29b6f6' }}>
                      View Full Bill Details
                    </Link>
                    <Divider sx={{ mt: 1, backgroundColor: '#616161' }} />
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography>No recent legislation data available.</Typography>
            )}
          </Paper>


        </>
      )}
    </Container>
  );
}

export default CongressMemberProfile;