import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {
  TrendingUp,
  ArrowForward,
  Leaderboard,
  Gavel
} from '@mui/icons-material';
import { styled, keyframes } from '@mui/material/styles';
import BackgroundAnimation from '../components/BackgroundAnimation';

const pulseAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
`;

const PulseEffect = styled('div')({
  position: 'fixed',
  borderRadius: '50%',
  pointerEvents: 'none',
  width: 200,
  height: 200,
  background: 'radial-gradient(circle, rgba(59,130,246,0.6) 0%, rgba(59,130,246,0) 70%)',
  transform: 'scale(0)',
  animation: `${pulseAnimation} 1s ease-out forwards`,
  zIndex: 1
});

const BackgroundContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  overflow: 'hidden',
  pointerEvents: 'none',  // Add this line
  '& > *': {              // Add this line
    pointerEvents: 'auto' // Add this line
  }
});


const MotionContainer = motion(Container);
const MotionCard = motion(Card);
const MotionTypography = motion(Typography);
const MotionBox = motion(Box);

const Home = ({ dollarsEnabled }) => {
  const [pulses, setPulses] = useState([]);

  const handleClick = (e) => {
    const newPulse = {
      id: Date.now(),
      x: e.clientX,
      y: e.clientY
    };
    setPulses(prev => [...prev, newPulse]);
    setTimeout(() => {
      setPulses(prev => prev.filter(p => p.id !== newPulse.id));
    }, 1000);
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, staggerChildren: 0.1 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <Box 
      sx={{ minHeight: '100vh', position: 'relative', zIndex: 0, overflow: 'hidden' }}
      onClick={handleClick}
    >
      <BackgroundContainer
        sx={{
          background: 'linear-gradient(180deg, rgba(25,118,210,0.1) 0%, rgba(0,0,0,0) 100%)',
          backgroundAttachment: 'fixed'
        }}
      />

      {pulses.map(pulse => (
        <PulseEffect
          key={pulse.id}
          style={{
            left: pulse.x - 100,
            top: pulse.y - 100,
          }}
        />
      ))}

      <BackgroundAnimation dollarsEnabled={dollarsEnabled} />

      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <MotionBox
          component="section"
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <MotionContainer maxWidth="lg" sx={{ textAlign: 'center', zIndex: 2 }}>
            <MotionTypography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: '2.5rem', md: '4rem' },
                mb: 4,
                background: 'linear-gradient(45deg, #fff, #90caf9)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              variants={itemVariants}
            >
              Track, Learn, Invest:<br/>
              You Can Trade Like Congress Too.
            </MotionTypography>
            <MotionTypography
              variant="h5"
              color="text.secondary"
              sx={{ mb: 6 }}
              variants={itemVariants}
            >
              capitolgains.io is democratizing access to stock trades made by U.S. Congress members<br/>
              so you can trade like an insider too.
              
            </MotionTypography>
            <MotionBox
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'center',
                flexDirection: { xs: 'column', sm: 'row' }
              }}
              variants={itemVariants}
            >
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/recent-trades"
                endIcon={<ArrowForward />}
                sx={{ minWidth: 200, borderRadius: '12px' }}
              >
                View Recent Trades
              </Button>
              <Button
                variant="outlined"
                size="large"
                component={Link}
                to="/about"
                endIcon={<ArrowForward />}
                sx={{ minWidth: 200, borderRadius: '12px' }}
              >
                Learn More
              </Button>
            </MotionBox>
          </MotionContainer>
        </MotionBox>

        <Box component="section" sx={{ py: 12 }}>
          <MotionContainer
            maxWidth="lg"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={containerVariants}
          >
            <Typography variant="h2" align="center" gutterBottom>
              Explore Congress Trading Data
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              {[
                {
                  icon: <Gavel fontSize="large" />,
                  title: "Congress Trade Tracking",
                  description: "Track congressional trading activities as they happen",
                  link: "/recent-trades"
                },
                {
                  icon: <Leaderboard fontSize="large" />,
                  title: "Congress Leaderboard",
                  description: "Discover which Congress members are the most successful traders",
                  link: "/leaderboard"
                },
                {
                  icon: <TrendingUp fontSize="large" />,
                  title: "Stock Rankings",
                  description: "Access rankings of stocks based on Congress members' activities",
                  link: "/stocks"
                }
              ].map((feature, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Link to={feature.link} style={{ textDecoration: 'none' }}>
                    <MotionCard
                      variants={itemVariants}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      sx={{ height: '100%', cursor: 'pointer', borderRadius: '24px' }}
                    >
                      <CardContent sx={{ textAlign: 'center', p: 4 }}>
                        <Box sx={{ color: 'primary.main', mb: 2 }}>
                          {feature.icon}
                        </Box>
                        <Typography variant="h5" gutterBottom>
                          {feature.title}
                        </Typography>
                        <Typography color="text.secondary">
                          {feature.description}
                        </Typography>
                      </CardContent>
                    </MotionCard>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </MotionContainer>
        </Box>

        <Box component="section" sx={{ py: 12, bgcolor: 'background.paper', borderRadius: 5 }}>
          <MotionContainer
            maxWidth="lg"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={containerVariants}
          >
            <Typography variant="h2" align="center" gutterBottom>
              What CapitolGains.io Offers
            </Typography>
            <Typography variant="h6" align="center" color="text.secondary" sx={{ mb: 8 }}>
              Discover the unique features and benefits that set us apart
            </Typography>
            <Grid container spacing={4}>
              {[
                {
                  icon: <BarChartIcon />,
                  title: "Comprehensive Data",
                  description: "See what big moves congress is making in the stock market right now"
                },
                {
                  icon: <NotificationsActiveIcon />,
                  title: "Custom Trading Alerts",
                  description: "Get notified as soon as your favorite congress members make stock trades"
                },
                {
                  icon: <ManageSearchIcon />,
                  title: "Track Money in Politics",
                  description: "Compare congress members' stock trades with policies they support"
                }
              ].map((item, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <MotionCard
                    variants={itemVariants}
                    sx={{ height: '100%', textAlign: 'center', p: 4, borderRadius: '24px' }}
                  >
                    <Box sx={{ color: 'primary.main', mb: 2 }}>
                      {item.icon}
                    </Box>
                    <Typography variant="h5" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.description}
                    </Typography>
                  </MotionCard>
                </Grid>
              ))}
            </Grid>
          </MotionContainer>
        </Box>

        <Box component="section" sx={{ py: 12 }}>
          <MotionContainer
            maxWidth="lg"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={containerVariants}
          >
            <Typography variant="h2" align="center" gutterBottom>
              Choose Your Plan
            </Typography>
            <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
              {[
                {
                  title: "Free Plan",
                  price: "$0",
                  features: [
                    "30-day delayed acces to recent trades",
                    "All historical trade data",
                    "Congress Trading Leaderboard",
                    "Community access"
                  ]
                },
                {
                  title: "Premium",
                  price: "$8 / Month",
                  features: [
                    "Instant access to latest trades",
                    "Congress member trading & policy analytics",
                    "Top congressional stock picks & rankings",
                    "Member tracking & real-time trade alerts"
                  ],
                  featured: true
                }
              ].map((plan, index) => (
                <Grid item xs={12} md={5} key={index}>
                  <MotionCard
                    variants={itemVariants}
                    whileHover={{ scale: 1.05 }}
                    sx={{
                      position: 'relative',
                      borderRadius: '24px',
                      overflow: 'hidden',
                      ...(plan.featured && {
                        borderColor: 'primary.main',
                        boxShadow: theme => `0 0 0 2px ${theme.palette.primary.main}`
                      })
                    }}
                  >
                    {plan.featured && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 20,
                          right: -30,
                          transform: 'rotate(45deg)',
                          bgcolor: 'primary.main',
                          color: 'white',
                          px: 4,
                          py: 0.5
                        }}
                      >
                        Premium
                      </Box>
                    )}
                    <CardContent sx={{ p: 4 }}>
                      <Typography variant="h4" gutterBottom>
                        {plan.title}
                      </Typography>
                      <Typography variant="h3" sx={{ mb: 4 }}>
                        {plan.price}
                      </Typography>
                      <Box component="ul" sx={{ p: 0, m: 0, listStyle: 'none' }}>
                        {plan.features.map((feature, i) => (
                          <Box
                            component="li"
                            key={i}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 2
                            }}
                          >
                            <ArrowForward sx={{ mr: 1, fontSize: 'small' }} />
                            <Typography>{feature}</Typography>
                          </Box>
                        ))}
                      </Box>
                      <Button
                        variant={plan.featured ? "contained" : "outlined"}
                        fullWidth
                        size="large"
                        sx={{ mt: 4, borderRadius: '12px' }}
                        component={Link}
                        to={plan.featured ? "/pricing" : "/sign-up"}
                      >
                        {plan.featured ? "Upgrade Now" : "Sign Up Free"}
                      </Button>
                    </CardContent>
                  </MotionCard>
                </Grid>
              ))}
            </Grid>
          </MotionContainer>
        </Box>

        <Box component="section" sx={{ py: 12, bgcolor: 'background.paper', borderRadius: 5  }}>
          <MotionContainer
            maxWidth="lg"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={containerVariants}
          >
            <Typography variant="h2" align="center" gutterBottom>
              FAQ
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              {[
                {
                  question: "How Are Congress Stock Trades Collected?",
                  answer: "We collect stock trade disclosures directly from publicly available sources, including the House and Senate websites, where members of Congress are required to file their stock trades to the public by law."
                },
                {
                  question: "How Often Does Congress Report Trades?",
                  answer: "Members of Congress must report their stock trades to the public within 45 days of the transaction by law under the STOCKS Act."
                },
                {
                  question: "How Our Stock Ranking Algorithm Works",
                  answer: "Our algorithm analyzes Congress members' trading patterns, focusing on long-term holding potential and historical performance to provide meaningful insights."
                }
              ].map((faq, index) => (
<Grid item xs={12} md={6} key={index}>
                  <MotionCard variants={itemVariants} sx={{ height: '100%', borderRadius: '24px' }}>
                    <CardContent sx={{ p: 4 }}>
                      <Typography variant="h5" gutterBottom>
                        {faq.question}
                      </Typography>
                      <Typography color="text.secondary">
                        {faq.answer}
                      </Typography>
                    </CardContent>
                  </MotionCard>
                </Grid>
              ))}
            </Grid>
          </MotionContainer>
        </Box>

        {/* Embedded YouTube Video Section */}
        <Box component="section" sx={{ py: 12 }}>
          <MotionContainer
            maxWidth="lg"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={containerVariants}
          >
            <Typography variant="h2" align="center" gutterBottom>
              Watch Capitol Gains in Action
            </Typography>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', borderRadius: 5 }}>
              <iframe width="100%" height="500" src="https://www.youtube.com/embed/KfONdZBMPsM?si=uyKHuZUzA3d6xAtv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </Box>
          </MotionContainer>
        </Box>

        <Box component="section" sx={{ py: 12 }}>
          <MotionContainer
            maxWidth="lg"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={containerVariants}
          >
            <Card sx={{ p: 8, textAlign: 'center', borderRadius: '24px' }}>
              <Typography variant="h3" gutterBottom>
                Ready to Get Started?
              </Typography>
              <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
                Join thousands of investors tracking big moves congress is making in the stock market.<br/>
                Signing up is free and no credit card is required.
              </Typography>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/sign-up"
                endIcon={<ArrowForward />}
                sx={{ minWidth: 200, borderRadius: '12px' }}
              >
                Create Free Account
              </Button>
            </Card>
          </MotionContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;