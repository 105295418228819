import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    Alert,
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Switch,
    FormControlLabel
} from '@mui/material';
import { fetchAuthSession } from 'aws-amplify/auth';
import TraderCard from '../components/TraderCard';
import { useInView } from 'react-intersection-observer';

function TradingLeaderboard() {
    const [leaders, setLeaders] = useState([]);
    const [filteredLeaders, setFilteredLeaders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortBy, setSortBy] = useState('default');
    const [showActiveOnly, setShowActiveOnly] = useState(true);
    const [visibleItems, setVisibleItems] = useState(10);
    const [trackedMembers, setTrackedMembers] = useState([]);
    const purchaseThreshold = 10;

    const { ref, inView } = useInView({
        threshold: 0.3,
    });

    // Fetch tracked members
    const fetchTrackedMembers = async (idToken) => {
        try {
            const response = await fetch('https://api.capitolgains.io/user/track-member', {
                headers: { Authorization: `Bearer ${idToken}` },
            });
            if (!response.ok) throw new Error('Failed to fetch tracked members');
            const data = await response.json();
            setTrackedMembers(data.tracked_members || []);
        } catch (error) {
            console.error('Error fetching tracked members:', error);
            // Don't set error state here to avoid disrupting the main view
        }
    };

    // Update tracked members state when a member is tracked/untracked
    const handleTrackingUpdate = (memberId, isCurrentlyTracked) => {
        setTrackedMembers(prev => 
            isCurrentlyTracked 
                ? prev.filter(id => id !== memberId)
                : [...prev, memberId]
        );
    };

    // Fetch session data and initialize data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const session = await fetchAuthSession();
                if (session && session.tokens && session.tokens.idToken) {
                    const idToken = session.tokens.idToken.toString();
                    
                    // Fetch leaders and tracked members in parallel
                    const [leadersResponse] = await Promise.all([
                        fetch('https://api.capitolgains.io/user/congress', {
                            headers: { Authorization: `Bearer ${idToken}` },
                        }),
                        fetchTrackedMembers(idToken)
                    ]);

                    if (!leadersResponse.ok) throw new Error('Network response was not ok');
                    const data = await leadersResponse.json();
                    const enrichedLeaders = (data.trader_stats || []).map(member => {
                        const score = (member.avg_returns?.stocks?.purchase?.roi || 0) * 100 * (member.bullish_transactions?.accuracy || 0);
                        return { ...member, score };
                    });
                    setLeaders(enrichedLeaders);
                    setLoading(false);
                } else throw new Error('Session is invalid or missing ID token');
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // Apply sorting and filtering
    useEffect(() => {
        const getSortValue = (member) => {
            const avgReturns = member.avg_returns || {};
            const stocksReturns = avgReturns.stocks || {};
            const purchaseROI = stocksReturns.purchase ? stocksReturns.purchase.roi : 0;
            const purchaseCount = member.bullish_transactions?.count || 0;
            const accuracy = member.bullish_transactions?.accuracy || 0;
            const purchaseVolume = member.bullish_transactions?.volume || 0;
            const profitEstimated = member.bullish_transactions?.profit_estimated || 0;

            switch (sortBy) {
                case 'averageROI':
                    return purchaseROI;
                case 'hitRate':
                    return accuracy;
                case 'numberOfPurchases':
                    return purchaseCount;
                case 'averageStockPurchase':
                    return purchaseVolume;
                case 'estimatedProfit':
                    return profitEstimated;
                default:
                    return member.score;
            }
        };

        const filteredAndSortedLeaders = leaders
            .filter(member => showActiveOnly ? member.member_details?.current_member === true : true)
            .filter(member => member.bullish_transactions?.count >= purchaseThreshold)
            .sort((a, b) => getSortValue(b) - getSortValue(a));

        setFilteredLeaders(filteredAndSortedLeaders);
    }, [leaders, sortBy, showActiveOnly]);

    // Lazy load additional items
    useEffect(() => {
        if (inView && visibleItems < filteredLeaders.length) {
            setVisibleItems(prevVisibleItems => prevVisibleItems + 10);
        }
    }, [inView, visibleItems, filteredLeaders.length]);

    if (loading) return <Container><Grid container justifyContent="center"><CircularProgress /></Grid></Container>;
    if (error) return <Container><Alert severity="error">Error: {error.message}</Alert></Container>;

    return (
        <Container>
            <Typography variant="h4" gutterBottom sx={{ color: '#ffffff' }}>
                Congressional Stock Trading Leaderboard
            </Typography>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: { xs: 'stretch', sm: 'center' },
                    gap: 2,
                    marginBottom: 3
                }}
            >
                <FormControl 
                    variant="outlined" 
                    sx={{ 
                        minWidth: { xs: '100%', sm: 200 }
                    }}
                >
                    <InputLabel id="sort-by-label">Sort By</InputLabel>
                    <Select
                        labelId="sort-by-label"
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        label="Sort By"
                    >
                        <MenuItem value="default">Capitol Gains Score</MenuItem>
                        <MenuItem value="averageROI">Annual Return</MenuItem>
                        <MenuItem value="hitRate">Hit Rate</MenuItem>
                        <MenuItem value="numberOfPurchases">Number of Purchases</MenuItem>
                        <MenuItem value="averageStockPurchase">Average Stock Purchase</MenuItem>
                        <MenuItem value="estimatedProfit">Estimated Total Profit</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Switch 
                            checked={showActiveOnly} 
                            onChange={(e) => setShowActiveOnly(e.target.checked)}
                        />
                    }
                    label="Show Only Active Members"
                    sx={{
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        borderRadius: 1,
                        padding: 1,
                        width: { xs: '100%', sm: 'auto' },
                        margin: 0,
                        justifyContent: 'space-between'
                    }}
                />
            </Box>
            <Grid container spacing={3}>
                {filteredLeaders.length === 0 ? (
                    <Grid item xs={12}>
                        <Alert severity="info">No data available.</Alert>
                    </Grid>
                ) : (
                    filteredLeaders.slice(0, visibleItems).map((member, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <TraderCard 
                                member={member} 
                                index={index}
                                isTracked={trackedMembers.includes(member.member_details?.bioguideId)}
                                onTrackingUpdate={handleTrackingUpdate}
                            />
                        </Grid>
                    ))
                )}
            </Grid>
            {visibleItems < filteredLeaders.length && (
                <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }} />
            )}
        </Container>
    );
}

export default TradingLeaderboard;