import React from 'react';

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <h1>Disclaimer</h1>
      
      <p>Last Updated: 11/01/2024</p>

      <h2>1. General Information and Scope of Content</h2>
      <p>
        CapitolGains.io ("we," "our," or "the platform") is a digital platform intended for informational and educational purposes. The content provided on CapitolGains.io does not constitute professional, financial, legal, or investment advice. While we strive to ensure the accuracy and completeness of the information provided, this content is presented "as-is" and without guarantees of any kind. Users should not rely solely on the information presented here for decision-making purposes and are encouraged to seek independent verification and professional consultation.
      </p>
      <p>
        The data, information, and resources available on CapitolGains.io are derived from publicly available sources and are subject to change without notice. We do not guarantee the timeliness, accuracy, relevance, or completeness of any information on the platform, nor are we obligated to update or maintain the data presented. By using CapitolGains.io, you agree that any reliance on the information provided is at your own risk and discretion. Users are encouraged to corroborate any information accessed here with independent and reputable sources.
      </p>

      <h2>2. No Financial, Legal, or Investment Advice</h2>
      <p>
        CapitolGains.io is not a registered financial advisor, legal advisor, or securities broker. The information presented on this platform is general and should not be interpreted as personal or tailored advice for any individual’s financial situation, investment objectives, or legal considerations. Content on CapitolGains.io, including articles, reports, analyses, and other materials, is intended for educational use only and is not intended to provide a basis for making investment decisions or choosing specific financial products.
      </p>
      <p>
        Users of CapitolGains.io are responsible for conducting their own research and due diligence, particularly if they are considering specific financial actions based on the information provided here. We strongly advise consulting with licensed professionals, such as certified financial planners, legal experts, and tax advisors, to obtain guidance suited to your individual circumstances. No aspect of CapitolGains.io's content should be considered a substitute for personal, individualized advice from a qualified advisor who is familiar with your unique financial situation and goals.
      </p>

      <h2>3. No Endorsement of Financial Products, Securities, or Services</h2>
      <p>
        CapitolGains.io does not recommend, endorse, or otherwise validate any specific investment strategies, financial products, or securities, including stocks, bonds, or other investment vehicles. Any reference to particular stocks, securities, or other financial instruments is purely for informational purposes based on public data and does not imply a recommendation by CapitolGains.io. Users should recognize that investment decisions involve a wide array of factors, and what may seem beneficial based on limited public information may not align with a user’s individual goals, risk tolerance, or financial position.
      </p>
      <p>
        CapitolGains.io's platform presents information about investments and trading activities for the purpose of promoting transparency and financial literacy. However, inclusion of any data on specific financial instruments should not be interpreted as an indication of the suitability, safety, or profitability of any investment. Users are encouraged to independently assess the viability and potential risks of any financial instrument and consider consulting with an investment professional prior to making any financial commitments.
      </p>

      <h2>4. Investment Risk and User Responsibility</h2>
      <p>
        All forms of investing are associated with inherent risks, including but not limited to market volatility, liquidity risk, loss of principal, and potential financial loss. Users of CapitolGains.io should recognize that past performance is not indicative of future results, and no data on this platform should be interpreted as a promise or guarantee of specific financial returns. By using CapitolGains.io, users acknowledge that they are solely responsible for their investment decisions, including assessing their risk tolerance, financial objectives, and the potential impact of market fluctuations on their investments.
      </p>
      <p>
        CapitolGains.io expressly disclaims liability for any financial loss, economic hardship, or adverse outcome that may arise from actions taken based on information accessed on this platform. Investment decisions should be made based on comprehensive analysis, consultation with professional advisors, and consideration of multiple factors. Users assume all risks associated with their financial actions and agree that CapitolGains.io shall not be held liable for any losses resulting from reliance on data, analyses, or resources provided here.
      </p>

      <h2>5. Limitation of Liability and Indemnification</h2>
      <p>
        CapitolGains.io, its affiliates, employees, officers, directors, and agents shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from or in connection with the use, misuse, or reliance on information provided on the platform. This includes, without limitation, damages related to investment losses, economic harm, or any other financial or non-financial loss. Users of CapitolGains.io expressly agree that the platform and its administrators cannot be held responsible for the accuracy, timeliness, or reliability of any content.
      </p>
      <p>
        By accessing CapitolGains.io, users agree to indemnify, defend, and hold harmless the platform and its affiliates from any and all claims, liabilities, damages, losses, or expenses, including reasonable attorney's fees and costs, that arise from or relate to the use of the platform, the reliance on its information, or any violation of this Disclaimer. Users acknowledge that they are solely responsible for their own actions and any consequences thereof.
      </p>

      <h2>6. Use of Publicly Reported Data from U.S. Congress Members</h2>
      <p>
        CapitolGains.io presents data on trading activities of U.S. Congress members as derived from publicly available disclosures, such as the STOCK Act and other mandatory reporting requirements. This data is solely for informational purposes and intended to enhance transparency in government-related financial activities. The data available on CapitolGains.io is not exhaustive and may not fully represent the financial positions of the individuals disclosed. Additionally, there may be delays in reporting, data inaccuracies, or missing information due to limitations in public reporting.
      </p>
      <p>
        Users should be aware that publicly disclosed data may omit various financial instruments, such as bonds, private equity, or alternative assets not subject to mandatory disclosure. CapitolGains.io makes no claim regarding the completeness or accuracy of such data, and users should not rely on it as a primary basis for any investment decisions. The data is provided “as-is,” and CapitolGains.io assumes no responsibility for any consequences arising from the interpretation or use of publicly reported Congressional trading data.
      </p>

      <h2>7. Limitations and Scope of Publicly Disclosed Information</h2>
      <p>
        The information related to the financial activities of public figures is compiled from legally mandated disclosures and may not reflect the full scope of their investments. Public disclosure requirements are limited in scope, often capturing only certain classes of securities or specific financial events. Users should understand that these disclosures are often subject to regulatory delays and, as such, may not reflect real-time positions or accurate portfolio compositions.
      </p>
      <p>
        CapitolGains.io advises users to consider the limitations inherent in such data and refrain from viewing this information as a comprehensive representation of any individual's portfolio or strategy. Any interpretations or inferences drawn from this data are the responsibility of the user, and CapitolGains.io disclaims liability for the accuracy, relevance, or implications of the disclosed information.
      </p>

      <h2>8. User Portfolios and Individual Responsibility</h2>
      <p>
        CapitolGains.io allows users to explore publicly available trading data for educational purposes. Any use of this data for personal investment decisions is undertaken at the user's sole discretion and responsibility. CapitolGains.io neither validates nor reviews user-created content, portfolios, or investment strategies, and users are solely accountable for the risks and outcomes of any financial actions they choose to take based on the platform’s data.
      </p>
      <p>
        Users are prohibited from relying on CapitolGains.io as a source of investment advice or as an authoritative guide on specific investment actions. By engaging with the platform, users acknowledge that any portfolios or strategies they may develop using CapitolGains.io’s data are for personal exploration only and do not constitute recommendations or professional endorsements.
      </p>

      <h2>9. No Fiduciary or Advisory Role of CapitolGains.io</h2>
      <p>
        CapitolGains.io does not act as an investment advisor, fiduciary, or financial intermediary. We do not assess, endorse, or recommend the suitability of any investment decisions made by users of this platform. CapitolGains.io’s role is strictly to provide information and educational tools, without any involvement in the individual financial decisions or fiduciary responsibilities of its users. The platform disclaims any duty to provide guidance, advice, or oversight with respect to user decisions.
      </p>

      <h2>10. Purpose as an Educational Tool and Non-Solicitation of Investment</h2>
      <p>
        CapitolGains.io’s mission is to foster financial literacy and understanding of publicly disclosed financial activities. The platform is designed to serve as a resource for users who seek educational insights and should not be viewed as an inducement or solicitation to invest in any particular security, stock, or financial strategy. CapitolGains.io does not engage in personalized investment advising and does not provide services that are regulated under financial advising or securities laws.
      </p>

      <h2>11. External Links and Responsibility for Third-Party Content</h2>
      <p>
        CapitolGains.io may contain links to external sites or third-party content. These links are offered as a convenience to users, but CapitolGains.io assumes no responsibility for the accuracy, relevance, or security of content found on external websites. The inclusion of third-party links does not imply endorsement by CapitolGains.io, and users access these resources at their own risk. We recommend users familiarize themselves with the terms and policies of any external sites they visit.
      </p>

      <h2>12. Forward-Looking Statements and Speculative Information</h2>
      <p>
        Content on CapitolGains.io may include forward-looking statements or speculative information that is inherently subjective. Such statements are based on projections, assumptions, or hypothetical scenarios and should not be interpreted as predictive, reliable, or as an endorsement of future events. Users are advised to perform independent research and seek expert consultation before making decisions based on speculative content.
      </p>

      <h2>13. Right to Modify Disclaimer and Continued Use</h2>
      <p>
        CapitolGains.io reserves the right to update or modify this Disclaimer at any time. Changes will be posted on this page with an updated date, and users are encouraged to review this page periodically. Continued use of CapitolGains.io following the posting of changes indicates acceptance of the revised Disclaimer. Users who disagree with any updates are advised to discontinue use of the platform.
      </p>

      <h2>14. Contact Us</h2>
      <p>
        For questions or concerns regarding this Disclaimer or other terms of use, please contact us at [contact email or form link].
      </p>
    </div>
  );
};

export default Disclaimer;