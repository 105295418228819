import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p><strong>Last Updated:</strong> 11/01/2024</p>
      
      <h2>1. Introduction</h2>
      <p>Welcome to CapitolGains.io ("we," "our," or "the platform"). These Terms of Service ("Terms") govern your access to and use of our website and services ("Service"). By accessing or using CapitolGains.io, you agree to be bound by these Terms, which establish a binding legal agreement between you and CapitolGains.io. If you do not agree to these Terms, please do not access or use our Service. It is your responsibility to read and understand these Terms before accessing the platform.</p>
      
      <h2>2. Acceptance of Terms</h2>
      <p>By using CapitolGains.io, you affirm that you are at least 18 years of age, or if under 18, you are accessing the platform with the consent of a legal guardian who agrees to be bound by these Terms on your behalf. You also agree that you possess the legal capacity to enter into these Terms. If you are using the Service on behalf of an organization or entity, you represent that you have the authority to bind the organization to these Terms. Any unauthorized access or use of the platform is strictly prohibited.</p>
      
      <h2>3. Modification of Terms</h2>
      <p>CapitolGains.io reserves the right to modify these Terms at any time, with or without prior notice. Any significant changes will be communicated by posting a notice on our website or by email notification, depending on the importance of the changes. It is your responsibility to review these Terms periodically. Your continued use of the Service after the changes become effective constitutes your acceptance of the new Terms. If you do not agree to any amended Terms, you must discontinue use of the Service.</p>
      
      <h2>4. Use of the Service</h2>
      <p>You agree to use CapitolGains.io only for lawful purposes and in compliance with these Terms and all applicable laws and regulations. You further agree not to engage in any of the following prohibited actions:</p>
      <ul>
        <li>Use the Service to post, share, or disseminate content that is unlawful, defamatory, harassing, fraudulent, obscene, or otherwise inappropriate.</li>
        <li>Engage in any conduct that restricts or inhibits another user's ability to use or enjoy the Service or that, in our judgment, may harm CapitolGains.io or users of the Service.</li>
        <li>Attempt to gain unauthorized access to the platform, its systems, servers, or data by hacking, password mining, or any other means.</li>
        <li>Use any device, software, or routine that interferes with the proper functioning of the Service, including but not limited to viruses, bots, or other malicious code.</li>
      </ul>
      <p>Violations of these rules may result in suspension, termination of access to the Service, or other legal action as deemed necessary by CapitolGains.io.</p>
      
      <h2>5. Account Responsibilities</h2>
      <p>To access certain features of CapitolGains.io, you may be required to create an account. You agree to provide accurate and complete information when registering, and to update your information as necessary. You are responsible for maintaining the confidentiality of your account credentials, including your password, and for any activities that occur under your account. CapitolGains.io will not be liable for any losses or damages resulting from unauthorized use of your account.</p>
      <p>You agree to notify us immediately at [contact email] if you suspect any unauthorized access to your account or any other security breach. We reserve the right to terminate or suspend your account if we have reason to believe you are violating these Terms or any applicable laws.</p>
      
      <h2>6. Intellectual Property Rights</h2>
      <p>All content on CapitolGains.io, including but not limited to text, graphics, logos, images, designs, code, and software, is the exclusive property of CapitolGains.io or its content suppliers and is protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. You may not copy, modify, distribute, create derivative works, or use any content from our platform without our prior written permission.</p>
      <p>All trademarks, logos, and service marks displayed on CapitolGains.io are our registered and unregistered marks or are used under license by third parties. Unauthorized use of any of these marks is prohibited. Violation of our intellectual property rights may result in legal action and suspension of your access to the platform.</p>

      <h2>7. User-Generated Content</h2>
      <p>CapitolGains.io may allow users to post, upload, or submit content, such as comments, images, or other materials ("User-Generated Content"). By posting content on CapitolGains.io, you grant CapitolGains.io a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and fully sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in any media. CapitolGains.io is not responsible for the content posted by users and does not endorse or verify its accuracy.</p>
      <p>We reserve the right, but are not obligated, to monitor, review, or remove User-Generated Content for any reason, including if it violates these Terms or applicable law. CapitolGains.io disclaims any liability for user content and reserves the right to suspend or terminate user accounts found to be in violation of our content policies.</p>

      <h2>8. Third-Party Links and Services</h2>
      <p>CapitolGains.io may contain links to third-party websites, applications, or services that are not owned or controlled by us. These links are provided for convenience only, and we are not responsible for the content, policies, or practices of any third-party sites. We do not endorse or assume liability for any third-party content accessed through links on our platform.</p>
      <p>By using third-party links, you acknowledge and agree that any reliance on or use of these external resources is at your own risk. We encourage you to read the terms and privacy policies of any third-party websites you visit through links on CapitolGains.io.</p>

      <h2>9. Termination of Service</h2>
      <p>We reserve the right to terminate or suspend your access to CapitolGains.io, without notice, for any reason, including but not limited to violations of these Terms, fraudulent activity, or any other conduct that we deem harmful to the platform or its users. Upon termination, your right to use the Service will cease immediately, and any User-Generated Content you have posted may be removed.</p>
      <p>If you wish to terminate your account voluntarily, you may do so by contacting [support email]. Please note that upon termination, you may lose access to certain features or content on the platform.</p>

      <h2>10. Disclaimer of Warranties</h2>
      <p>CapitolGains.io is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the platform, its functionality, or the content available on it. This includes but is not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, and freedom from computer viruses or harmful code. CapitolGains.io does not warrant that the platform will operate without interruption, be error-free, or that any issues will be corrected.</p>
      <p>Your use of the platform and reliance on any information presented here is entirely at your own risk. We encourage you to seek independent advice or verification before making any decisions based on the content provided by CapitolGains.io.</p>

      <h2>11. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, CapitolGains.io, its affiliates, employees, officers, directors, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, goodwill, or other intangible losses, arising out of or in connection with (i) your access to or use of, or inability to access or use, the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; or (iv) any bugs, viruses, or other harmful code that may be transmitted to or through our Service by any third party.</p>
      <p>In jurisdictions where limitations of liability are not fully enforceable, our liability is limited to the minimum amount legally permissible. This section shall survive the termination of your account or these Terms.</p>

      <h2>12. Indemnification</h2>
      <p>You agree to defend, indemnify, and hold harmless CapitolGains.io, its affiliates, employees, contractors, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney’s fees) arising from: (i) your use of and access to the Service; (ii) your violation of any term of these Terms; (iii) your violation of any third-party right, including but not limited to any copyright, property, or privacy right; or (iv) any claim that your content caused damage to a third party.</p>
      <p>This defense and indemnification obligation will survive these Terms and your use of the Service.</p>

      <h2>13. Governing Law and Dispute Resolution</h2>
      <p>These Terms and any disputes arising out of or related to them or the Service shall be governed by and construed in accordance with the laws of the jurisdiction in which CapitolGains.io is headquartered, without regard to its conflict of law principles. You agree to submit to the personal jurisdiction of the courts located within that jurisdiction for any actions or claims arising out of or relating to these Terms or your use of the Service.</p>

      <h2>14. Changes to Terms of Service</h2>
      <p>CapitolGains.io reserves the right to update, modify, or terminate these Terms at any time. Significant changes will be communicated to users through a notice on our website or an email. Your continued use of CapitolGains.io following the posting of updated Terms constitutes acceptance of those changes. If you disagree with any changes, you are advised to discontinue use of the platform.</p>

      <h2>15. Contact Information</h2>
      <p>If you have questions or concerns about these Terms, please reach out to us at info@capitolgains.io.</p>
    </div>
  );
};

export default TermsOfService;