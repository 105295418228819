const awsmobile = {
    aws_project_region: 'us-west-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_IlYI3z6L8',
    aws_user_pools_web_client_id: '49lji85fmb2mu4qtk592euco1k',
    oauth: {
        domain: 'auth.capitolgains.io',
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
        responseType: 'code',
    },
};

export default awsmobile;
