import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InfoTooltip = ({ title }) => {
    return (
        <Tooltip
            title={title}
            arrow
            sx={{
                color: '#e0e0e0',
                fontSize: '0.875rem',
            }}
        >
            <IconButton size="small">
                <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    );
};

export default InfoTooltip;