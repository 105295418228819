import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      
      <p>Last Updated: October 3, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        CapitolGains.io ("we", "us", or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information 
        when you visit our website and use our services. By accessing or using our website, you agree to the terms outlined in this policy.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        We may collect various types of information from you, including:
      </p>
      <ul>
        <li><strong>Personal Information:</strong> Information that you voluntarily provide to us, such as your name, email address, phone number, and payment details.</li>
        <li><strong>Usage Data:</strong> Information about how you use our website, including your IP address, browser type, access times, and pages viewed.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> Information collected through cookies, web beacons, and similar technologies. For more details, please refer to our 
        <a href="/cookie-policy">Cookie Policy</a>.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>
        We use your information for various purposes, including:
      </p>
      <ul>
        <li>To provide and maintain our services.</li>
        <li>To process your transactions and manage your account.</li>
        <li>To communicate with you, including responding to your inquiries and sending promotional materials.</li>
        <li>To analyze and improve our website and services.</li>
        <li>To comply with legal obligations and protect our rights and interests.</li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <p>
        We may share your information with third parties in the following circumstances:
      </p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers to help us operate our website and provide our services.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information to comply with applicable laws, regulations, or legal requests, or to protect our rights and interests.</li>
        <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your information may be transferred as part of that business transaction.</li>
      </ul>

      <h2>5. Your Rights and Choices</h2>
      <p>
        Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, delete, or restrict its use. You may also have the right 
        to object to certain processing activities or request the transfer of your data. To exercise any of these rights, please contact us at <a href="mailto:info@capitolgains.io">info@capitolgains.io</a>.
      </p>

      <h2>6. Data Security</h2>
      <p>
        We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or method of 
        electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2>7. Retention of Your Information</h2>
      <p>
        We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
      </p>

      <h2>8. Third-Party Websites</h2>
      <p>
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies 
        before providing any personal information.
      </p>

      <h2>9. Consent to Data Collection</h2>
      <p>
        By using our website, you agree to the collection, processing, and sharing of your personal information as described in this Privacy Policy. You have the option to withdraw consent at any time by contacting us at <a href="mailto:info@capitolgains.io">info@capitolgains.io</a>.
      </p>

      <h2>10. Legal Basis for Processing Your Information (EU Users)</h2>
      <p>
        For users in the European Union, we process your personal information under the following legal bases:
      </p>
      <ul>
        <li><strong>Performance of a contract:</strong> We process your information to fulfill our contractual obligations.</li>
        <li><strong>Consent:</strong> Where we rely on your consent to process your data, you have the right to withdraw consent at any time.</li>
        <li><strong>Legitimate Interests:</strong> We may process your data where necessary for our legitimate business interests, provided those interests are not overridden by your rights.</li>
      </ul>

      <h2>11. Your California Privacy Rights (CCPA)</h2>
      <p>
        If you are a California resident, you have the right to request information regarding the disclosure of your personal information to third parties for direct marketing purposes. Under the California Consumer Privacy Act (CCPA), you also have the right to:
      </p>
      <ul>
        <li>Request access to your personal information.</li>
        <li>Request the deletion of your personal information.</li>
        <li>Opt out of the sale of your personal information (if applicable).</li>
      </ul>
      <p>To exercise any of these rights, please contact us at <a href="mailto:info@capitolgains.io">info@capitolgains.io</a>.</p>

      <h2>12. Children’s Privacy</h2>
      <p>
        Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe we have collected personal information from your child, please contact us immediately at <a href="mailto:info@capitolgains.io">info@capitolgains.io</a>.
      </p>

      <h2>13. Use of Analytics and Tracking Tools</h2>
      <p>
        We use third-party analytics services to help us understand how our website is being used. These services may collect information about your use of the website, including your IP address and usage data. For more details, please refer to our <a href="/cookie-policy">Cookie Policy</a>.
      </p>

      <h2>14. Authentication and Account Security</h2>
      <p>
        We take security seriously and implement measures such as encrypted passwords and secure authentication protocols to protect your account information. However, no online system is completely secure, and you are responsible for maintaining the confidentiality of your login credentials.
      </p>

      <h2>15. Data Transfers</h2>
      <p>
        We may transfer and store your data on servers located outside of your home country. By using our website, you agree to the transfer of your information to countries with different data protection standards. We will take all reasonable steps to ensure your data is treated securely.
      </p>

      <h2>16. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website after changes are made signifies 
        your acceptance of the revised policy.
      </p>

      <h2>17. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:info@capitolgains.io">info@capitolgains.io</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;