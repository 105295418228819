import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Check if the user is in the Free or Premium Cognito group
 * @returns {Promise<{ isFree: boolean, isPremium: boolean }>} - An object indicating the user's group
 */
export const checkUserGroup = async () => {
    try {
        const session = await fetchAuthSession();
        if (session && session.tokens && session.tokens.idToken) {
            const groups = session.tokens.idToken.payload['cognito:groups'] || [];
            const isFree = groups.includes('Free');
            const isPremium = groups.includes('Premium');
            return { isFree, isPremium };
        } else {
            console.log('No session or groups found');
            return { isFree: false, isPremium: false };
        }
    } catch (error) {
        console.error('Error fetching user groups', error);
        return { isFree: false, isPremium: false };
    }
};