import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Link, CircularProgress, Button } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const ManageSubscription = () => {
    const [billingHistory, setBillingHistory] = useState({ data: [] });
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [canceling, setCanceling] = useState(false);
    const [jwtToken, setJwtToken] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSessionAndData = async () => {
            try {
                const session = await fetchAuthSession();
                const token = session.tokens.idToken.toString();
                setJwtToken(token);

                // Fetch billing history
                const historyResponse = await fetch('https://api.capitolgains.io/user/billing-history', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                if (!historyResponse.ok) throw new Error('Failed to fetch billing history.');
                const historyData = await historyResponse.json();

                // Fetch subscription details
                const subscriptionResponse = await fetch('https://api.capitolgains.io/user/subscription-details', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                if (!subscriptionResponse.ok) throw new Error('Failed to fetch subscription details.');
                const subscriptionData = await subscriptionResponse.json();

                // Update state
                setBillingHistory(historyData.invoices || { data: [] });
                setSubscriptionDetails(subscriptionData);
            } catch (error) {
                setError('Failed to load subscription data.');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSessionAndData();
    }, []);

    const handleCancelSubscription = async () => {
        setCanceling(true);
        try {
            const response = await fetch('https://api.capitolgains.io/user/cancel-subscription', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${jwtToken}` },
            });

            if (!response.ok) throw new Error('Failed to cancel subscription.');
            const result = await response.json();
            setSubscriptionDetails(result);

            await fetchAuthSession({ forceRefresh: true });
            navigate('/account');
        } catch (error) {
            setError('Failed to cancel subscription.');
            console.error(error);
        } finally {
            setCanceling(false);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Container>
            <Box sx={{ paddingTop: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Manage Subscription
                </Typography>

                {subscriptionDetails ? (
                    <>
                        {subscriptionDetails.status === 'canceled' && subscriptionDetails.end_date ? (
                            <Alert severity="info" sx={{ mb: 2 }}>
                                Your subscription has been canceled. You still have access until{' '}
                                <strong>{new Date(subscriptionDetails.end_date.replace(' ', 'T')).toLocaleDateString()}</strong>.
                                After this date, your premium access will be revoked.
                            </Alert>
                        ) : subscriptionDetails.cancel_at ? (
                            <Alert severity="info" sx={{ mb: 2 }}>
                                Your subscription is active and scheduled to cancel on{' '}
                                <strong>{new Date(subscriptionDetails.cancel_at * 1000).toLocaleDateString()}</strong>.
                            </Alert>
                        ) : subscriptionDetails.status === 'active' && subscriptionDetails.next_billing_date ? (
                            <Typography variant="h6" gutterBottom>
                                Next Billing Date: {new Date(subscriptionDetails.next_billing_date.replace(' ', 'T')).toLocaleDateString()}
                            </Typography>
                        ) : (
                            <Box sx={{ my: 2 }}>
                                <Typography variant="h6" color="textSecondary">
                                    Your subscription has been canceled, and no further billing will occur.
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    If you wish to resubscribe, please visit the <Link href="/pricing">Pricing</Link> page.
                                </Typography>
                            </Box>
                        )}

                        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                            Billing History
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Billed To</TableCell>
                                        <TableCell>Created On</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {billingHistory.data.map(invoice => (
                                        <TableRow key={invoice.id}>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <WorkspacePremiumIcon style={{ color: 'gold', marginRight: 8 }} />
                                                    <div>
                                                        <Typography variant="body2">{invoice.lines.data[0]?.description}</Typography>
                                                        <Typography variant="caption">Invoice #: {invoice.number}</Typography>
                                                    </div>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{invoice.customer_email}</TableCell>
                                            <TableCell>{new Date(invoice.created * 1000).toLocaleDateString()}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                                                    color={invoice.status === 'paid' ? 'success' : 'primary'}
                                                />
                                            </TableCell>
                                            <TableCell>${(invoice.amount_paid / 100).toFixed(2)}</TableCell>
                                            <TableCell>
                                                <Link href={invoice.invoice_pdf} target="_blank" rel="noopener">
                                                    View invoice
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {subscriptionDetails.status === 'active' && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleCancelSubscription}
                                sx={{ mt: 4 }}
                                disabled={canceling}
                            >
                                {canceling ? <CircularProgress size={24} color="inherit" /> : 'Cancel Subscription'}
                            </Button>
                        )}
                    </>
                ) : (
                    <Typography variant="h6" gutterBottom>
                        Your subscription details are currently unavailable.
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default ManageSubscription;