import { motion } from 'framer-motion';
import React, { useState } from 'react';
import {
  TrendingUp,
  TrendingDown,
  Groups,
  AccessTime as Timer,
  ShowChart,
  Person,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@mui/icons-material';


import StockPicksGraph from '../components/StockPicksGraph';

import {
  Container,
  Typography,
  Box,
  Stack,
  Collapse,
  Card
} from '@mui/material';

// Define motion components
const MotionBox = motion(Box);
const MotionCard = motion(Card);
const MotionTypography = motion(Typography);

const StockRankingsInfo = () => {
  const [graphExpanded, setGraphExpanded] = useState(false);
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <MotionBox
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <Stack spacing={6}>
          {/* Header Section */}
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <MotionTypography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: 700,
                background: 'linear-gradient(45deg, #fff, #90caf9)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 3
              }}
              variants={itemVariants}
            >
              How Stock Rankings Are Determined
            </MotionTypography>
            <MotionTypography 
              variant="h5" 
              color="text.secondary"
              sx={{ maxWidth: '800px', mx: 'auto' }}
              variants={itemVariants}
            >
              Our comprehensive scoring system analyzes Congress members' trading activities
              to help you make informed investment decisions.
            </MotionTypography>
          </Box>

          {/* Factors That Increase Ranking */}
          <MotionCard
            variants={itemVariants}
            sx={{
              borderRadius: '24px',
              background: 'linear-gradient(145deg, rgba(25,118,210,0.05) 0%, rgba(25,118,210,0.1) 100%)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(144, 202, 249, 0.2)',
              overflow: 'hidden'
            }}
          >
            <Box
              sx={{
                background: 'linear-gradient(135deg, rgba(25,118,210,0.2) 0%, rgba(25,118,210,0.4) 100%)',
                p: 4,
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <TrendingUp sx={{ color: '#4caf50', fontSize: 32 }} />
                <Typography variant="h4" sx={{ color: '#fff' }}>
                  Factors That Increase Ranking
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ p: 4 }}>
              <Stack spacing={3}>
                {[
                  {
                    icon: <ShowChart />,
                    title: "Higher Purchase Volume",
                    description: "Large quantities of stock purchases positively impact ranking"
                  },
                  {
                    icon: <Timer />,
                    title: "More Frequent Purchases",
                    description: "Regular buying activity increases the stock's score"
                  },
                  {
                    icon: <Groups />,
                    title: "Multiple Buyers",
                    description: "More individual buyers suggest broader interest and confidence"
                  },
                  {
                    icon: <Person />,
                    title: "High Buyer Credibility",
                    description: "Buyers with strong track records increase confidence in the purchase"
                  },
                ].map((item, index) => (
                  <Box key={index} sx={{ display: 'flex', gap: 3, alignItems: 'flex-start' }}>
                    <Box
                      sx={{
                        p: 1,
                        borderRadius: '12px',
                        bgcolor: 'rgba(76, 175, 80, 0.1)',
                        color: '#4caf50',
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Box>
                      <Typography variant="h6" sx={{ color: '#fff', mb: 1 }}>
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Box>
          </MotionCard>

          {/* Factors That Decrease Ranking */}
          <MotionCard
            variants={itemVariants}
            sx={{
              borderRadius: '24px',
              background: 'linear-gradient(145deg, rgba(244,67,54,0.05) 0%, rgba(244,67,54,0.1) 100%)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(244,67,54,0.2)',
              overflow: 'hidden'
            }}
          >
            <Box
              sx={{
                background: 'linear-gradient(135deg, rgba(244,67,54,0.2) 0%, rgba(244,67,54,0.4) 100%)',
                p: 4,
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <TrendingDown sx={{ color: '#f44336', fontSize: 32 }} />
                <Typography variant="h4" sx={{ color: '#fff' }}>
                  Factors That Decrease Ranking
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ p: 4 }}>
              <Stack spacing={3}>
                {[
                  {
                    icon: <ShowChart />,
                    title: "Higher Sale Volume",
                    description: "Large quantities of stock sales negatively impact ranking"
                  },
                  {
                    icon: <Timer />,
                    title: "More Frequent Sales",
                    description: "Regular selling activity decreases the stock's score"
                  },
                  {
                    icon: <Groups />,
                    title: "Multiple Sellers",
                    description: "More individual sellers suggest declining confidence"
                  },
                  {
                    icon: <Person />,
                    title: "High Seller Credibility",
                    description: "Sellers with strong track records increase concern about the stock"
                  },
                ].map((item, index) => (
                  <Box key={index} sx={{ display: 'flex', gap: 3, alignItems: 'flex-start' }}>
                    <Box
                      sx={{
                        p: 1,
                        borderRadius: '12px',
                        bgcolor: 'rgba(244,67,54,0.1)',
                        color: '#f44336',
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Box>
                      <Typography variant="h6" sx={{ color: '#fff', mb: 1 }}>
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Box>
          </MotionCard>

          <Card
                    sx={{
                        mb: 6,
                        borderRadius: '24px',
                        background: 'linear-gradient(145deg, rgba(25,118,210,0.05) 0%, rgba(25,118,210,0.1) 100%)',
                        border: '1px solid rgba(144, 202, 249, 0.2)',
                    }}
                >
                    <Box
                        sx={{
                            cursor: 'pointer',
                            p: 4,
                        }}
                        onClick={() => setGraphExpanded(!graphExpanded)}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" spacing={2} alignItems="center">
                                <TrendingUp sx={{ color: 'primary.main', fontSize: 32 }} />
                                <Box>
                                    <Typography variant="h4" sx={{ color: 'primary.main', mb: 1 }}>
                                        Historical Track Record
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        View our proven history of outperforming the S&P 500 with data-driven stock picks when purchasing stocks determined to be "Very Bullish" based on our algorithm.
                                        {!graphExpanded && " - Click to expand"}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'primary.main',
                                    bgcolor: 'rgba(144, 202, 249, 0.1)',
                                    borderRadius: 2,
                                    p: '8px 16px',
                                }}
                            >
                                <Typography variant="button" sx={{ mr: 1 }}>
                                    {graphExpanded ? 'Hide Performance' : 'View Performance'}
                                </Typography>
                                {graphExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </Box>
                        </Stack>
                    </Box>

                    <Collapse in={graphExpanded}>
                        <Box sx={{ p: 4, pt: 0 }}>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                                See how Capitol Gains' stock picks have consistently generated higher returns compared to the S&P 500 benchmark.<br/>
                                Our algorithm's historical performance demonstrates our ability to identify high-potential stocks based on Congress Member's purchase history.
                            </Typography>
                            <StockPicksGraph />
                        </Box>
                    </Collapse>
                </Card>

          {/* Summary Section */}
          <MotionCard
            variants={itemVariants}
            sx={{
              p: 4,
              borderRadius: '24px',
              background: 'linear-gradient(145deg, rgba(25,118,210,0.05) 0%, rgba(25,118,210,0.1) 100%)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(144, 202, 249, 0.2)',
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: '#fff' }}>
              Summary
            </Typography>
            <Typography color="text.secondary" paragraph>
              The overall ranking of a stock is determined by balancing positive factors against negative ones,
              providing a comprehensive view of Congress members&apos; trading activities. This approach ensures that
              the rankings reflect both bullish and bearish sentiments, giving you clear insights into market dynamics.
            </Typography>
            <Typography color="text.secondary">
              Our straightforward system helps you understand why certain stocks are ranked higher or lower
              based on the latest data from U.S. Congress members, enabling you to make more informed investment decisions.
            </Typography>
          </MotionCard>
        </Stack>
      </MotionBox>
    </Container>
  );
};

export default StockRankingsInfo;