import React, { useState } from 'react';
import {
    Box,
    Typography,
    Stack,
    Avatar,
    Button,
    useMediaQuery,
    Card,
    Tooltip,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PercentIcon from '@mui/icons-material/Percent';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaidIcon from '@mui/icons-material/Paid';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Link } from 'react-router-dom';
import InfoTooltip from './InfoTooltip';
import { useTheme } from '@mui/material/styles';
import { fetchAuthSession } from 'aws-amplify/auth';
import { motion } from 'framer-motion';

const MotionCard = motion(Card);

const TraderCard = ({ member, index, isTracked, onTrackingUpdate }) => {
    // ... Previous state and constant declarations remain the same ...
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);

    const {
        name,
        member_details = {},
        avg_returns = {},
        bullish_transactions = {},
    } = member;

    const purchaseROI = avg_returns.stocks?.purchase?.roi || 0;
    const purchaseCount = bullish_transactions.count || 0;
    const accuracy = bullish_transactions.accuracy || 0;
    const purchaseVolume = bullish_transactions.volume || 0;
    const profitEstimated = bullish_transactions.profit_estimated || 0;

    const roiPercent = ((purchaseROI - 1) * 100).toFixed(2);
    const formattedVolume = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(purchaseVolume);
    const formattedProfitEstimated = profitEstimated
        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(profitEstimated)
        : 'N/A';

    const memberImage = member_details?.image || 'https://via.placeholder.com/150';
    const memberParty = member_details?.party || 'Unknown Party';
    const memberState = member_details?.state || 'Unknown State';
    const currentMemberText = member_details?.current_member ? 'Yes' : 'No';
    const partyColor = memberParty === 'Democratic' ? '#1976d2' : memberParty === 'Republican' ? '#d32f2f' : '#757575';

    const memberId = member_details?.bioguideId;

    const handleTrackClick = async () => {
        if (!memberId) return;
        setIsLoading(true);
        
        try {
            const session = await fetchAuthSession();
            const idToken = session.tokens.idToken.toString();

            const response = await fetch('https://api.capitolgains.io/user/track-member', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({
                    members: [memberId],
                }),
            });

            if (!response.ok) throw new Error('Failed to update tracking status');
            
            await response.json();
            onTrackingUpdate(memberId, isTracked);
        } catch (error) {
            console.error('Error updating tracking status:', error);
            alert('Failed to update tracking status. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    // Card header remains the same until the stats section
    return (
        <MotionCard
            elevation={0}
            whileHover={{ scale: 1.02, boxShadow: '0px 8px 25px rgba(0,0,0,0.15)' }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            sx={{
                borderRadius: '24px',
                background: 'linear-gradient(145deg, rgba(25,118,210,0.05) 0%, rgba(25,118,210,0.1) 100%)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(144, 202, 249, 0.2)',
                overflow: 'hidden',
            }}
        >
            {/* Card Header */}
            <Box
                sx={{
                    background: 'linear-gradient(135deg, rgba(25,118,210,0.2) 0%, rgba(25,118,210,0.4) 100%)',
                    padding: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Box 
                    sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, minWidth: 0 }}>
                        <Avatar
                            src={memberImage}
                            alt={`${name} profile`}
                            sx={{ 
                                width: { xs: 48, sm: 60 },
                                height: { xs: 48, sm: 60 },
                                marginRight: 2,
                                border: '2px solid rgba(144, 202, 249, 0.3)',
                                background: '#1a1a1a'
                            }}
                        />
                        <Box sx={{ flex: 1, minWidth: 0 }}>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 600,
                                    fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                    mb: 0.5,
                                    background: 'linear-gradient(45deg, #fff, #90caf9)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    textShadow: '0px 2px 4px rgba(0,0,0,0.2)',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                <Link
                                    to={`/congressmember/${memberId || ''}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    {name}
                                </Link>
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <CircleIcon 
                                    sx={{ 
                                        fontSize: { xs: 10, sm: 12 },
                                        color: partyColor,
                                        marginRight: 1,
                                        filter: `drop-shadow(0 0 4px ${partyColor}40)`
                                    }} 
                                />
                                <Typography 
                                    variant="subtitle2" 
                                    sx={{ 
                                        color: 'rgba(255,255,255,0.7)',
                                        fontSize: { xs: '0.8rem', sm: '0.875rem' }
                                    }}
                                >
                                    {memberParty} - {memberState}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Typography 
                        variant="h4" 
                        sx={{ 
                            color: 'primary.main',
                            fontWeight: 700,
                            textShadow: '0px 2px 4px rgba(0,0,0,0.3)',
                            fontSize: { xs: '1.5rem', sm: '2rem' },
                            marginLeft: 2
                        }}
                    >
                        #{index + 1}
                    </Typography>
                </Box>

                <Tooltip 
                    title={isTracked ? 
                        "Remove this Congress member from your email notification watchlist." : 
                        "Add this Congress member to your watchlist to get email notifications as soon as they make new trades."
                    }
                    placement="top"
                >
                    <Button
                        variant="contained"
                        size={isMobile ? "small" : "medium"}
                        onClick={handleTrackClick}
                        disabled={isLoading}
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            borderRadius: '12px',
                            py: { xs: 1, sm: 1.5 },
                            fontSize: { xs: '0.8125rem', sm: '0.875rem' },
                            color: '#ffffff',
                            bgcolor: isTracked ? 
                                'rgba(46, 174, 52, 0.8)' :  // Green when tracking (not hovered)
                                'rgba(59, 130, 246, 0.8)',   // Blue for track button
                            '&:hover': {
                                bgcolor: isTracked ? 
                                    'rgba(239, 68, 68, 0.9)' :  // Red on hover for untrack
                                    'rgba(59, 130, 246, 0.9)',   // Darker blue for track button hover
                            },
                            transition: 'all 0.2s ease',
                            fontWeight: 500,
                        }}
                    >
                        {isLoading ? 'Updating...' : isTracked ? 'Remove from Watchlist' : 'Add to Watchlist'}
                    </Button>
                </Tooltip>
            </Box>

            {/* Stats Section with Icons */}
            <Box sx={{ p: { xs: 2, sm: 4 } }}>
                <Stack spacing={2.5}>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                            <ShowChartIcon sx={{ 
                                color: purchaseROI >= 1 ? '#76ff03' : '#ff1744',
                                filter: `drop-shadow(0 0 4px ${purchaseROI >= 1 ? '#76ff0340' : '#ff174440'})`,
                            }} />
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: purchaseROI >= 1 ? '#76ff03' : '#ff1744',
                                    textShadow: `0 0 8px ${purchaseROI >= 1 ? '#76ff0340' : '#ff174440'}`,
                                }}
                            >
                                <strong>Average Annual Return:</strong> {roiPercent}%
                                <InfoTooltip title="The average annual percentage return of the investments made." />
                            </Typography>
                        </Box>
                        <Box sx={{ height: '1px', background: 'rgba(144, 202, 249, 0.1)', my: 1 }} />
                    </Box>

                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                            <PercentIcon sx={{ color: 'rgba(255,255,255,0.9)' }} />
                            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>
                                <strong>Hit Rate:</strong> {(accuracy * 100).toFixed(2)}%
                                <InfoTooltip title="The percentage of trades that were successful or profitable." />
                            </Typography>
                        </Box>
                        <Box sx={{ height: '1px', background: 'rgba(144, 202, 249, 0.1)', my: 1 }} />
                    </Box>

                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                            <ShoppingCartIcon sx={{ color: 'rgba(255,255,255,0.9)' }} />
                            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>
                                <strong>Number of Purchases:</strong> {purchaseCount}
                                <InfoTooltip title="The total number of stock purchases made." />
                            </Typography>
                        </Box>
                        <Box sx={{ height: '1px', background: 'rgba(144, 202, 249, 0.1)', my: 1 }} />
                    </Box>

                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                            <PaidIcon sx={{ color: 'rgba(255,255,255,0.9)' }} />
                            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>
                                <strong>Average Stock Purchase:</strong> {formattedVolume}
                                <InfoTooltip title="The average dollar amount spent per stock purchase." />
                            </Typography>
                        </Box>
                        <Box sx={{ height: '1px', background: 'rgba(144, 202, 249, 0.1)', my: 1 }} />
                    </Box>

                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                            <TrendingUpIcon sx={{ color: 'rgba(255,255,255,0.9)' }} />
                            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>
                                <strong>Estimated Profit:</strong> {formattedProfitEstimated}
                                <InfoTooltip title="Estimated profit for the trader, assuming all assets were sold exactly one year after purchase." />
                            </Typography>
                        </Box>
                        <Box sx={{ height: '1px', background: 'rgba(144, 202, 249, 0.1)', my: 1 }} />
                    </Box>

                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                            <AccountBoxIcon sx={{ color: 'rgba(255,255,255,0.9)' }} />
                            <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>
                                <strong>Current Member:</strong> {currentMemberText}
                                <InfoTooltip title="Indicates whether the member is currently in office." />
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </MotionCard>
    );
};

export default TraderCard;