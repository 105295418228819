import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Divider, Paper, Button, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const features = [
    { feature: "Access to Historical Congress Stock Trades", free: "Limited & Delayed", premium: true, developer: true },
    { feature: "Congressional Trader Leaderboard", free: "Limited", premium: true, developer: true },
    { feature: "Digest Emails", free: "Limited", premium: true, developer: true },
    { feature: "Access to Historical Congress Options Trades", free: false, premium: true, developer: true },
    { feature: "Instant Access to Latest Trades", free: false, premium: true, developer: true },
    { feature: "Congress-Based Stock Picks & Rankings", free: false, premium: true, developer: true },
    { feature: "Congress Member Trade & Policy Analytics", free: false, premium: true, developer: true },
    { feature: "Congress Member Trade Alerts", free: false, premium: true, developer: true },
    { feature: "Customizable Alert Settings", free: false, premium: true, developer: true },
    { feature: "Full Data Exports (CSV, Excel, JSON)", free: false, premium: false, developer: true },
    { feature: "API Access", free: false, premium: false, developer: true },
    { feature: "Dedicated IT Support", free: false, premium: false, developer: true },
];

const MotionContainer = motion(Container);
const MotionCard = motion(Card);

const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.6, staggerChildren: 0.1 }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const getIcon = (available, isMobile = false) => {
    if (typeof available === 'boolean') {
        if (available) {
            return (
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    color: 'primary.main',
                }}>
                    <CheckIcon 
                        sx={{ 
                            color: 'primary.main',
                            background: 'rgba(33,150,243,0.1)',
                            borderRadius: '50%',
                            p: 0.5,
                        }} 
                    />
                </Box>
            );
        } else if (isMobile) {
            return (
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    color: 'text.disabled',
                }}>
                    <CloseIcon 
                        sx={{ 
                            color: 'error.light',
                            opacity: 0.5,
                            fontSize: '1rem'
                        }} 
                    />
                </Box>
            );
        }
        return null;
    }
    return (
        <Typography 
            variant="body2" 
            sx={{ 
                color: 'text.secondary',
                fontSize: '0.875rem',
                fontWeight: 500
            }}
        >
            {available}
        </Typography>
    );
};

const Pricing = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box 
            sx={{ 
                minHeight: '100vh',
                position: 'relative',
                pt: 12,
                pb: 12
            }}
        >
            <MotionContainer
                maxWidth="lg"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <Typography 
                    variant="h2" 
                    align="center" 
                    sx={{
                        fontWeight: 700,
                        fontSize: { xs: '2.5rem', md: '4rem' },
                        mb: 4,
                        background: 'linear-gradient(45deg, #fff, #90caf9)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                    }}
                >
                    Choose Your Plan
                </Typography>
                <Typography 
                    variant="h5" 
                    color="text.secondary" 
                    align="center" 
                    sx={{ mb: 8 }}
                >
                    Get unlimited access to real-time Congress trading insights
                </Typography>

                {!isMobile && (
                    <TableContainer 
                        component={Paper} 
                        sx={{ 
                            borderRadius: '24px',
                            bgcolor: 'background.paper',
                            overflow: 'hidden',
                            boxShadow: theme.shadows[20],
                            '& .MuiTableCell-root': {
                                borderColor: 'rgba(255,255,255,0.1)'
                            }
                        }}
                    >
                        <Table aria-label="pricing table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ height: '50px' }}>
                                            <Typography variant="h6" sx={{ lineHeight: '50px' }}>Free</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell 
                                        align="center" 
                                        sx={{ 
                                            background: 'linear-gradient(180deg, rgba(33,150,243,0.2) 0%, rgba(33,150,243,0.1) 100%)',
                                            borderLeft: '1px solid rgba(33,150,243,0.3)',
                                            borderRight: '1px solid rgba(33,150,243,0.3)',
                                            position: 'relative',
                                            '&::after': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                height: '2px',
                                                background: 'linear-gradient(90deg, rgba(33,150,243,0) 0%, rgba(33,150,243,1) 50%, rgba(33,150,243,0) 100%)'
                                            }
                                        }}
                                    >
                                        <Typography variant="h6">Premium</Typography>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            component={Link} 
                                            to="/account"
                                            sx={{ 
                                                mt: 1,
                                                borderRadius: '12px',
                                                py: 1,
                                                px: 3,
                                                textTransform: 'none',
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                boxShadow: theme.shadows[10],
                                                '&:hover': {
                                                    boxShadow: theme.shadows[20],
                                                }
                                            }}
                                        >
                                            Upgrade
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Developer+</Typography>
                                        <Button 
                                            variant="contained" 
                                            disabled
                                            sx={{ 
                                                mt: 1,
                                                borderRadius: '12px',
                                                py: 1,
                                                px: 3,
                                                textTransform: 'none',
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Coming Soon
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h5">$0 / month</Typography>
                                    </TableCell>
                                    <TableCell 
                                        align="center" 
                                        sx={{ 
                                            background: 'linear-gradient(180deg, rgba(33,150,243,0.2) 0%, rgba(33,150,243,0.1) 100%)',
                                        }}
                                    >
                                        <Typography variant="h5">$8 / month</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h5">Contact Sales</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {features.map((row) => (
                                    <TableRow key={row.feature}>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="body1">{row.feature}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {getIcon(row.free)}
                                        </TableCell>
                                        <TableCell 
                                            align="center"
                                            sx={{ 
                                                background: 'linear-gradient(180deg, rgba(33,150,243,0.2) 0%, rgba(33,150,243,0.1) 100%)',
                                            }}
                                        >
                                            {getIcon(row.premium)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {getIcon(row.developer)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {isMobile && (
                    <Grid container spacing={3}>
                        {['free', 'premium', 'developer'].map((tier) => (
                            <Grid item xs={12} key={tier}>
                                <MotionCard 
                                    variants={itemVariants}
                                    sx={{ 
                                        padding: '24px',
                                        borderRadius: '24px',
                                        background: tier === 'premium' 
                                            ? 'linear-gradient(145deg, rgba(33,150,243,0.15) 0%, rgba(33,150,243,0.05) 100%)'
                                            : 'background.paper',
                                        boxShadow: theme.shadows[10],
                                        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-8px)',
                                            boxShadow: theme.shadows[20],
                                        },
                                        border: tier === 'premium' ? '1px solid rgba(33,150,243,0.3)' : 'none'
                                    }}
                                >
                                    <CardContent>
                                        <Typography 
                                            variant="subtitle1" 
                                            gutterBottom 
                                            sx={{ 
                                                fontWeight: 'bold', 
                                                textTransform: 'uppercase', 
                                                color: '#555', 
                                                textAlign: 'left' 
                                            }}
                                        >
                                            {tier.charAt(0).toUpperCase() + tier.slice(1)}
                                        </Typography>
                                        <Typography 
                                            variant="h3" 
                                            gutterBottom 
                                            sx={{ 
                                                fontWeight: 'bold', 
                                                marginTop: '-10px', 
                                                textAlign: 'left' 
                                            }}
                                        >
                                            {tier === 'free' ? '$0' : tier === 'premium' ? '$8' : '$99'}
                                            <Typography 
                                                variant="subtitle2" 
                                                component="span" 
                                                sx={{ 
                                                    marginLeft: 1, 
                                                    fontSize: '1rem', 
                                                    color: '#888' 
                                                }}
                                            >
                                                / month
                                            </Typography>
                                        </Typography>
                                        <Divider sx={{ marginY: 2 }} />
                                        <Box sx={{ textAlign: 'left' }}>
                                            {features.map((row, idx) => (
                                                <Box 
                                                    key={idx} 
                                                    display="flex" 
                                                    alignItems="center" 
                                                    justifyContent="space-between" 
                                                    sx={{ 
                                                        py: 1.5,
                                                        borderBottom: '1px solid',
                                                        borderColor: 'divider',
                                                        '&:last-child': {
                                                            borderBottom: 'none'
                                                        }
                                                    }}
                                                >
                                                    <Typography 
                                                        variant="body2" 
                                                        sx={{ 
                                                            flexGrow: 1,
                                                            color: row[tier] ? 'text.primary' : 'text.secondary',
                                                            fontWeight: row[tier] ? 500 : 400,
                                                        }}
                                                    >
                                                        {row.feature}
                                                    </Typography>
                                                    <Box sx={{ ml: 2, minWidth: '80px', textAlign: 'right' }}>
                                                        {tier === 'free' && row.free ? row.free : getIcon(row[tier], true)}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                        <Button 
                                            variant={tier === 'premium' ? 'contained' : 'outlined'}
                                            color="primary" 
                                            component={Link}
                                            to="/account"
                                            disabled={tier === 'developer'}
                                            sx={{ 
                                                marginTop: 3, 
                                                width: '100%',
                                                borderRadius: '12px',
                                                py: 1.5,
                                                textTransform: 'none',
                                                fontSize: '1.1rem',
                                                fontWeight: 500,
                                                boxShadow: tier === 'premium' ? theme.shadows[10] : 'none',
                                                '&:hover': {
                                                    boxShadow: tier === 'premium' ? theme.shadows[20] : 'none',
                                                }
                                            }}
                                        >
                                            {tier === 'developer' ? 'Coming Soon' : tier === 'free' ? 'Get Started' : 'Upgrade'}
                                        </Button>
                                    </CardContent>
                                </MotionCard>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </MotionContainer>
        </Box>
    );
};

export default Pricing;