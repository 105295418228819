import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, TextField, Alert, CircularProgress } from '@mui/material';
import { fetchAuthSession } from '@aws-amplify/auth';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import { checkUserGroup } from '../utils/authUtils'; // Import the helper function

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Account = () => {
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        phone_number: '',
        jwtToken: '',
    });
    const [isPremiumUser, setIsPremiumUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const fetchAttributes = async (forceRefresh = false) => {
        try {
            const session = await fetchAuthSession({ forceRefresh });
            if (session && session.tokens && session.tokens.idToken) {
                const jwtToken = session.tokens.idToken.toString();
                const groupInfo = await checkUserGroup(); // Fetch the user's group info using the helper function
                setIsPremiumUser(groupInfo.isPremium); // Only set isPremiumUser

                // Set the profile with relevant fields
                setProfile({
                    name: session.tokens.idToken.payload.name || '',
                    email: session.tokens.idToken.payload.email || '',
                    phone_number: session.tokens.idToken.payload.phone_number || '',
                    jwtToken,
                });
            } else {
                console.log('No tokens found in session');
            }
        } catch (err) {
            console.log('Error fetching user attributes', err);
        }
    };

    useEffect(() => {
        fetchAttributes();
    }, []);

    const handleUpgrade = async () => {
        setLoading(true);
        try {
            console.log('Profile before upgrade:', profile);

            if (!profile.jwtToken) {
                console.error('No JWT token available.');
                setLoading(false);
                return;
            }

            if (!stripe || !elements) {
                console.error('Stripe.js has not loaded yet.');
                setLoading(false);
                return;
            }

            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.error('Error creating payment method:', error.message);
                setLoading(false);
                return;
            }

            const response = await fetch('https://api.capitolgains.io/user/create-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${profile.jwtToken}`,
                },
                body: JSON.stringify({
                    email: profile.email,
                    paymentMethodId: paymentMethod.id,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const { message } = await response.json();

            console.log('Server response:', message);

            if (message.includes('Subscription created, payment confirmed')) {
                await fetchAttributes(true);
                console.log('Session updated after subscription');
            }
        } catch (error) {
            console.error('Error during the upgrade process:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container sx={{ paddingTop: 8, paddingBottom: 8 }}>
            <Box sx={{ padding: 4, backgroundColor: 'rgba(24, 24, 24, 0.9)', borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom sx={{ color: '#90caf9', fontWeight: 'bold', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)' }}>
                    Account Information
                </Typography>

                <Typography variant="h6" gutterBottom sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                    Profile Details
                </Typography>

                {profile.name && (
                    <TextField
                        fullWidth
                        label="Name"
                        value={profile.name}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{
                            input: { color: '#ffffff' },
                            label: { color: '#90caf9' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#90caf9' },
                                '&:hover fieldset': { borderColor: '#ffffff' },
                            },
                        }}
                    />
                )}

                {profile.email && (
                    <TextField
                        fullWidth
                        label="Email"
                        value={profile.email}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{
                            input: { color: '#ffffff' },
                            label: { color: '#90caf9' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#90caf9' },
                                '&:hover fieldset': { borderColor: '#ffffff' },
                            },
                        }}
                    />
                )}

                {profile.phone_number && (
                    <TextField
                        fullWidth
                        label="Phone Number"
                        value={profile.phone_number}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{
                            input: { color: '#ffffff' },
                            label: { color: '#90caf9' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#90caf9' },
                                '&:hover fieldset': { borderColor: '#ffffff' },
                            },
                        }}
                    />
                )}

                <Typography variant="h6" gutterBottom sx={{ mt: 4, color: '#ffffff', fontWeight: 'bold' }}>
                    Subscription Status
                </Typography>

                {isPremiumUser ? (
                    <Box>
                        <Alert severity="success" sx={{ mb: 2, backgroundColor: 'rgba(41, 121, 255, 0.2)', color: '#ffffff' }}>
                            You are a <strong>Premium Subscriber</strong>! Thank you for your support!
                        </Alert>
                        <Button variant="contained" color="primary" sx={{ mr: 2, backgroundColor: '#1e88e5', '&:hover': { backgroundColor: '#1565c0' } }} component={Link} to="/manage-subscription">
                            Manage Subscription
                        </Button>
                    </Box>
                ) : (
                    <Box>
                        <Alert severity="info" sx={{ mb: 2, backgroundColor: 'rgba(144, 202, 249, 0.1)', color: '#ffffff' }}>
                            You are currently on the <strong>Free</strong> plan. Sign up for Premium access for $8 / month.
                        </Alert>
                        <Box sx={{ mb: 2, padding: 2, border: '1px solid #90caf9', borderRadius: 2, backgroundColor: '#121212' }}>
                            <CardElement options={{ style: { base: { color: '#ffffff', fontSize: '16px', '::placeholder': { color: '#b0bec5' } }, invalid: { color: '#f44336' } } }} />
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpgrade}
                            disabled={loading}
                            sx={{ backgroundColor: '#1e88e5', '&:hover': { backgroundColor: '#1565c0' } }}
                        >
                            {loading ? <CircularProgress size={24} sx={{ color: '#ffffff' }} /> : 'Upgrade to Premium'}
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

const AccountWrapper = () => (
    <Elements stripe={stripePromise}>
        <Account />
    </Elements>
);

export default AccountWrapper;